import React, { useEffect, useMemo, useState } from 'react'
import logo from '../../../assets/restaurant/images/logo.svg';
import thai from '../../../assets/restaurant/images/thai.png';
import chinese from '../../../assets/restaurant/images/chinese.png';
import english from '../../../assets/restaurant/images/english.png';
import hindi from '../../../assets/restaurant/images/hindi.png';
import japanese from '../../../assets/restaurant/images/japanese.png';
import nepalese from '../../../assets/restaurant/images/nepalese.png';

import { useTranslation } from 'react-i18next'
import { getLocalKey, getRestaurantUser, logOutRestaurant, setLocalKey } from '../../../utils/commonfunction';
import { Link, NavLink, useLocation } from 'react-router-dom';
import i18next from 'i18next';
import { SOCKET_CLIENT } from '../../../utils/socket';
import { useDispatch } from 'react-redux';
import { addRestProfileData } from '../../../redux/slices/restSettings';
import { useSelector } from 'react-redux';

export default function Header() {
  const { t } = useTranslation();
  const [restaurantUserData, setRestaurantUserData] = useState({});
  const [flag, setFlag] = useState(getLocalKey("system_language_flag") || english);
  const location = useLocation();
  const dispatch = useDispatch();

  const changeLanguage = (lang, flag) => {
    i18next.changeLanguage(lang);
    setLocalKey("system_language", lang);
    setLocalKey("system_language_flag", flag);
    setFlag(flag);
    const evt = new CustomEvent("language_change");
    document.dispatchEvent(evt);
  }

 
  useEffect(() => {
    const userData = getRestaurantUser();
    setRestaurantUserData(userData); 
    dispatch(addRestProfileData(userData));
  }, [])

  const isActive=(url="/")=>{
    return window?.location?.pathname?.includes(url);
  }
  function testSocket(){
    let message = prompt("Enter message") || "Hello from socket";
    SOCKET_CLIENT.emit('testSocket', message, (error) => {
      if (error) {
        console.log("*****Socket connection error on notificationSend", error);
      }
    })
  }
  useMemo(() => {
    SOCKET_CLIENT.on('testSocketReply', async (response) => {
      console.log("Data", response);
    });
    SOCKET_CLIENT.on('GET-DATA', async (response) => {
      console.log("Data", response);
    });
  }, []);
  const restDetails = useSelector((state) => state?.restSettings?.profileData);

  // console.log(restDetails);
  return (
    <div className='header'>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand"><img src={logo} alt=''/></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to={"/restaurant/dashboard"} className="nav-link" aria-current="page"><i className="ti ti-home"></i>{t("nav_link_home")}</NavLink>
              </li>
              <li className="nav-item">
              <NavLink to={"/restaurant/sales"} className="nav-link" aria-current="page"><i className='ti ti-chart-bar'></i>{t("nav_link_sales")}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink end to={"/restaurant/menu"} className={"nav-link "+(isActive('/restaurant/menu') ?" active":"")} aria-current="page"><i className='ti ti-template'></i>{t("nav_link_menu")}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink end to={"/restaurant/table-management/table/1"} className={"nav-link "+(isActive('/restaurant/table-management') ?" active":"")}><i className='ti ti-brand-airtable'></i>{t("nav_link_table_management")}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink end to={"/restaurant/settings/staff/1"} className={"nav-link "+(isActive('/restaurant/settings')?" active":"")} ><i className='ti ti-settings'></i>{t("nav_link_settings")}</NavLink>
              </li>
              <li className="nav-item d-none">
                <a onClick={testSocket} className="nav-link cp">Test Socket</a>
              </li>
            </ul>
            <div className='d-flex gap-3'>
              <div className="dropdown profile-dropdown lang-dropdown ">
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className='avatar avatar-xxs'>
                    <img src={flag} alt='Flag' />
                  </div>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item cp" onClick={(e) => { e.preventDefault(); changeLanguage("ja", japanese) }}>
                      <figure className='avatar avatar-xxs'>
                        <img src={japanese} alt='Japanese-flag' />
                      </figure>
                      <span>
                        Japanese
                      </span>
                    </a>
                  </li>
                  <li>
                    <Link className="dropdown-item" href="#" onClick={(e) => {e.preventDefault(); changeLanguage("en", english)}}>
                      <figure className='avatar avatar-xxs'>
                        <img src={english} alt='UK-flag' />
                      </figure>
                      <span>
                        English
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" href="#" onClick={(e) => {e.preventDefault();changeLanguage("ne", nepalese)}}>
                      <figure className='avatar avatar-xxs'>
                        <img src={nepalese} alt='Nepalese-flag' />
                      </figure>
                      <span>
                        Nepalese
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" href="#" onClick={(e) => {e.preventDefault();changeLanguage("zh", chinese)}}>
                      <figure className='avatar avatar-xxs'>
                        <img src={chinese} alt='Chinese-flag' />
                      </figure>
                      <span>
                        Chinese
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" href="#" onClick={(e) => {e.preventDefault();changeLanguage("hi", hindi)}}>
                      <figure className='avatar avatar-xxs'>
                        <img src={hindi} alt='India-flag' />
                      </figure>
                      <span>
                        Hindi
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" href="#" onClick={(e) =>{e.preventDefault();changeLanguage("th", thai)}}>
                      <figure className='avatar avatar-xxs'>
                        <img src={thai} alt='Thai-falg' />
                      </figure>
                      <span>
                        Thai
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dropdown profile-dropdown">
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className='avatar avatar-xxs'>
                    <img src={restDetails?.profile_image} alt='profile-img' />
                  </div>
                  <span>{restDetails?.first_name}<i className='ti ti-chevron-down'></i></span>
                </button>
                <ul className="dropdown-menu">
                  <div className='profile-drop-head-out'>
                    <div className='profile-drop-head'>
                      <figure className='mx-auto avatar avatar-xs'>
                        <img src={restDetails?.profile_image} alt='profile-img' />
                      </figure>
                      <figcaption>
                        <h4>{restDetails?.first_name}</h4>
                        <p>{restDetails?.email}</p>
                      </figcaption>
                    </div>
                  </div>
                  <li>
                    <Link to={`/restaurant/profile`} className="dropdown-item" ><i className='ti ti-user'></i>{t("link_my_profile")}</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={`/restaurant/profile/restaurant-profile`}><i className='ti ti-building-store'></i>{t("link_restaurant_profile")}</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/restaurant/profile/change-password"><i className='ti ti-lock'></i>{t("change_password")}</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/restaurant/profile/contract-information"><i className='ti ti-notes'></i>{t("link_contract_information")}</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={`/restaurant/profile/restaurant-profile`}><i className='ti ti-chart-bar'></i> {t("fiscal_year")}</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/restaurant/profile/mail-notification-settings/1"><i className='ti ti-mail-opened'></i>{t("link_mail_notification_settings")}</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" onClick={(e) => { e.preventDefault(); logOutRestaurant() }}><i className='ti ti-logout'></i>{t("label_logout")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}
