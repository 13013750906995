import React from "react";
import WebsiteTestimonials from "../../common/websitetestimonials";
import Inquiry from "../../common/inquiry";
import { useTranslation } from "react-i18next";
import ManualGuideData from "../../shared/data/manualGuide.json";
import { useNavigate } from "react-router-dom";

function WebsiteManualGuide() {
  const { t } = useTranslation();
  const Navigate = useNavigate();

  return (
    <div className="manual-guide-page">
      <section className="how-work-sec manual-guide-sec section-padding">
        <div className="container">
          <div className="headings  mb-5 text-center">
            <h4>{t("web_manual_guide")}</h4>
            <h2>{t("web_manual_guide_heading_text")}</h2>
          </div>
          <div className="row how-box">
            {ManualGuideData.map((item) => {
              return (
                <div
                  className="col-md-3 cp"
                  onClick={() => {
                    Navigate(`/manual-guide-details/${item.slug}/${item.tabs[0].slug}`);
                  }}
                >
                  <article>
                    <figure>
                      <span>
                        <img src={item.icon} alt="" />
                      </span>
                    </figure>
                    <figcaption>
                      <h4>{item.title}</h4>
                      <p>{item.description} </p>
                    </figcaption>
                  </article>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="testimonial-section section-padding pt-0">
        <WebsiteTestimonials id="testimonial-2" />
      </section>
      <Inquiry id="inquiry-2" />
    </div>
  );
}

export default WebsiteManualGuide;
