import React, { useEffect, useRef, useState } from "react";
import RecordNotFound from "../../../../restaurant/shared/recordNotFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter, formateDate, get24HoursFormatedTime, globalLoader, handleServerValidations, showPrice, TrimText, useDebouncing } from "../../../../../utils/commonfunction";
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION, SWAL_SETTINGS } from "../../../../../utils/Constants";
import CustomPagination from "../../../../admin/common/custompagination";
import { deleteTransactionSlip, editSalesSlipCashier, SalesHistoryData, transactionDetail } from "../../../services/sales.service";
import Swal from "sweetalert2";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import ReactToPrint from "react-to-print";
import { list as TableList } from "../../../services/table.services";
import { staffList as StaffList } from "../../../services/auth.services";
import { useFormik } from "formik";
import { useValidationSchemas } from "../../../../restaurant/validator/validation";
import CustomError from "../../../../../utils/customError";
import DefaultProfileImg from "../../../../../assets/restaurant/images/default_user_profile.png";
import { useSelector } from "react-redux";

export default function SalesHistory() {
  const { t } = useTranslation();
  const [List, setList] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const params = useParams();
  const [prevGlobalSearch, setPrevGlobalSearch] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [search, setSearch] = useState({});
  const [reservationDetail, setReservationDetail] = useState({});
  const [page, setPage] = useState(1);
  const [offset, setOffsetPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [transactionData, setTransactionData] = useState(null);
  const closeModalRef = useRef();
  const [itemPerPage] = useState(10);
  const printSlipRef = useRef();
  const [editModal, setEditModal] = useState(false);
  const { editSlipValidationSchema } = useValidationSchemas();
  const [staffArray, setStaffArray] = useState([]);
  const [tableListArray, setTableArrayList] = useState([]);
  const { validatePassword } = useValidationSchemas();
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [show, setShow] = useState("false");
  const isCashRegisterDone = useSelector((state) => state?.cashierDetails?.data);
  const openModal = useRef();
  const closePasswordRef = useRef();
  const [isDirty, setIsDirty] = useState(true)
  const [defaultOrderData, setDefaultOrderData] = useState({})
  const navigate = useNavigate();

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const prepareGlobalSearch = (value) => {
    setPrevGlobalSearch(globalSearch);
    setGlobalSearch(value);
  };
  const searchDebounce = useDebouncing(prepareGlobalSearch);

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    searchDebounce(value);
  };

  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    if (Object.keys(search).length) {
      formData.append("search", JSON.stringify(search));
    }
    formData.append("global_search", globalSearch);
    let page = params.page;
    if (globalSearch) {
      if (prevGlobalSearch !== globalSearch || !prevGlobalSearch) {
        page = 1;
      }
      setPage(page);
    }
    formData.append("page", page);
    formData.append("per_page", itemPerPage);
    SalesHistoryData(formData)
      .then((data) => {
        if (data && data.data) {
          setList(data.data.list || []);
          setPage(data.data.page);
          setOffsetPage(data.data.offset);
          setTotalRecords(data.data.total_records || 0);
        } else {
          setList([]);
          setOffsetPage(0);
          setTotalRecords(0);
        }
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [search, refreshList, globalSearch, params.page]);

  const getTransactionDetail = (o_id) => {
    transactionDetail({ o_id: o_id })
      .then((response) => {
        setTransactionData(response && response.data ? response.data : []);
        setDefaultOrderData({
          transaction_id: response?.data?._id || "",
          number_of_persons: response?.data?.number_of_persons || 0,
          men: response?.data?.men || 0,
          women: response?.data?.women || 0,
          payment_method: response?.data?.payment_method || "",
          type: response?.data?.type || "",
          cashier_id: response?.data?.cashier_id?._id || "",
          table_id: response?.data?.tableDetail?._id || "",
        })
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };


  const handleDelete = (_id) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_delete_text"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        deleteTransactionSlip({ _id: _id })
          .then((response) => {
            globalLoader(false);
            if (response.success) {
              closeModalRef.current.click();
              setRefreshList(!refreshList);
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch(() => {
            globalLoader(false);
          });
      }
    });
  };

  const addSlipChanges = useFormik({
    initialValues: {
      transaction_id: transactionData?._id || "",
      number_of_persons: transactionData?.number_of_persons || 0,
      men: transactionData?.men || 0,
      women: transactionData?.women || 0,
      payment_method: transactionData?.payment_method || "",
      type: transactionData?.type || "",
      cashier_id: transactionData?.cashier_id?._id || "",
      table_id: transactionData?.tableDetail?._id || "",
    },
    validationSchema: editSlipValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => formData.append(key, value));
      setSelectedStaff(isCashRegisterDone);

      try {
        if (values.type === "cancelled" && transactionData.type !== "cancelled") {
          Swal.fire({
            title: t("msg_are_you_sure"),
            text: t("cancel_sales_slip_text_"),
            imageUrl: trash,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: t("btn_cancel_reservation"),
            cancelButtonText: t("false_label_no"),
            reverseButtons: true,
            customClass: {
              popup: "swal-restaurant",
              confirmButton: "btn btn-danger col",
              cancelButton: "btn btn-secondary col",
            },
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              closeModalRef.current.click();
              openModal.current.click();
            }
          });
        }
        else {
          closeModalRef.current.click();
          openModal.current.click();
        }


      } catch (error) {
        closeModalRef.current.click();
        setEditModal(false);
        console.error("Error adding table", error);
      }
    },
  });
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validatePassword,
    onSubmit: async (values, { setSubmitting, resetForm }) => {


      try {
        const data = {
          ...addSlipChanges.values, email: selectedStaff.email,
          password: values.password
        };
        const response = await editSalesSlipCashier(data);


        if (response.success) {
          setRefreshList(!refreshList);
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
          resetForm();
          addSlipChanges.resetForm();
        } else {
          setRefreshList(!refreshList);
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
          resetForm();
          addSlipChanges.resetForm();
          setSubmitting(false)

        }
        closePasswordRef.current.click();
      } catch (error) {

        closePasswordRef.current.click();
        console.error("Error adding table", error);
      }
    },
  });

  const handleshow = (event) => {
    setShow(!show);
  };

  useEffect(() => {
    TableList()
      .then((response) => {
        setTableArrayList(response.data.list);
      })
      .catch((err) => {
        console.log(err);
      });
    StaffList()
      .then((response) => {
        setStaffArray(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <div className="cashier-heading-innner mb-4">
      <div className="card-outer-header d-flex justify-content-between align-items-center">
        <h4 className="heading-title-text">{t("label_sales_history")} </h4>
        <div className="card-head-group d-flex align-items-center gap-4">
          <div className="search-box">
            <input type="text" value={searchValue} className="form-control search-input  sales-history-input" placeholder={t("input_placeholder_search")} onChange={handleSearchChange} />
          </div>
        </div>
        <button data-bs-toggle="modal" data-bs-target="#modal-users-single1" style={{ display: "none" }} ref={openModal}></button>
      </div>

      <div className="dashboard-content-wraper mt-4">
        <div className="category-list-wrap">
          {loader ? (
            <div className="w-100">
              <Skeleton className="mb-2" height={50} count={5} />
            </div>
          ) : List?.length ? (
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{t("s_no")}</th>
                        <th> {t("label_table_number")} </th>
                        <th> {t("input_placeholder_date")}</th>
                        <th>{t("label_time")}</th>
                        <th>{t("label_total_amount")}</th>
                        <th>{t("label_choose_payment_method")}</th>
                        <th style={{ width: "150px" }}>{t("label_action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {List.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{offset + index + 1}</td>
                            <td>{TrimText(item?.tableDetail?.table_number, 15)}</td>
                            <td>{formateDate(item?.createdAt)}</td>
                            <td>{get24HoursFormatedTime(item?.createdAt)}</td>
                            <td> ¥ {showPrice(item?.amount_after_discount || 0, 3)}</td>
                            <td>{capitalizeFirstLetter(item?.payment_method || t("label_not_entered"))}</td>

                            <td className="d-flex">
                              <a
                                className="btn btn-primary "
                                data-bs-toggle="modal"
                                data-bs-target="#open-modal"
                                onClick={() => {
                                  getTransactionDetail(item?._id);
                                }}
                                style={{ width: "120px" }}
                              >
                                {t("btn_view")}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="table-status-info d-flex justify-content-between align-items-center">
                  <span>
                    {t("label_showing")} {offset + List.length} of {totalRecords} {t("label_entries")}
                  </span>
                  <nav aria-label="Page navigation example" title={page}>
                    {totalRecords && totalRecords > 0 ? (
                      <CustomPagination
                        datalength={totalRecords}
                        itemperpage={itemPerPage}
                        currentPage={page}
                        custompath="page"
                        setPage={setPage}
                        pageRoute={[{ name: "sales-history", path: "/cashier/settings/sales-history" }]}
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </div>
              </div>
            </div>
          ) : (
            <RecordNotFound heading="lbl_record_not_found" />
          )}
        </div>
      </div>

      <div className="modal fade " data-bs-backdrop="static" id="open-modal" tabIndex="-1" aria-labelledby="sort-modalLabel" aria-hidden="true">
        {/* <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"> */}
        {!editModal === true ? (
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content" style={{ borderRadius: "10px" }}>
              <div className="modal-header d-flex justify-content-between">
                <h1 className="modal-title fs-5 text-center w-100" id="exampleModalLabel">
                  {t('label_slip_number')} : {String(transactionData?.slip_number)?.padStart(4, "0") || ""}
                </h1>
                <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close" id="close_modal" ref={closeModalRef}>
                  <i className="ti ti-x"></i>
                </button>
              </div>

              <div className="modal-body p-0" >
                <div className="sales-slip-details-box">
                  <div className="sales list-info" style={{ width: "100%", borderRight: "none" }}>
                    <ul>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_person_in_charge")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{transactionData?.cashier_id?.name}</span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_table_no")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{transactionData?.tableDetail?.table_number}</span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_no_of_people")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>
                            {t("label_all")} : {transactionData?.number_of_persons || 0}
                          </span>
                          <br></br>
                          <span>
                            {t("label_men")} : {transactionData?.men || 0}
                          </span>
                          <br></br>
                          <span>
                            {t("label_woman")}: {transactionData?.women || 0}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_choose_payment_method")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{capitalizeFirstLetter(transactionData?.payment_method)}</span>
                        </div>
                      </li>

                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_date_time")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{formateDate(transactionData?.createdAt, "DD/MM/YYYY", true, false)}</span>
                        </div>
                      </li>
                      <li>
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_type")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>{transactionData?.type === "cancelled" ? t("label_cancelled") : t("label_sales")}</span>
                        </div>
                      </li>
                      <li className="list-border-none">
                        <div className="list-info-left">
                          <span style={{ fontSize: "18px", fontWeight: 600, color: "black" }}>{t("label_sales")}</span>
                        </div>
                      </li>
                      <li className="list-border-none">
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_sub_total")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>¥ {showPrice(transactionData?.subtotal)} </span>
                        </div>
                      </li>
                      <li className="list-border-none">
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_discount_if_applicable")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>
                            {transactionData?.gift_card_applied || transactionData?.coupon_applied ? `¥ ${showPrice(transactionData?.great_grand_total - transactionData?.amount_after_discount)}` : 0}
                          </span>
                        </div>
                      </li>
                      <li className="list-border-none">
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_consumption_tax")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>¥ {showPrice(transactionData?.tax_price || 0)}</span>
                        </div>
                      </li>
                      {
                        transactionData?.service_charge > 0 &&
                        <li className="list-border-none">
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("service_charge")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>¥ {showPrice(transactionData?.service_charge || 0)}</span>
                        </div>
                      </li>
                      }
                     
                      <li className="list-border-none">
                        <div className="list-info-left">
                          <span style={{ color: "black" }}>{t("label_total_capitalize")}</span>
                        </div>
                        <div className="list-info-right">
                          <span>
                            <b>¥ {showPrice(transactionData?.amount_after_discount || 0, 3)}</b>
                          </span>
                          <br></br>
                          <span>
                            8% {t("tax_on_text")} ¥ {showPrice(transactionData?.total_tax_8)}
                          </span>
                          <br></br>
                          <span>
                            10% {t("tax_on_text")} ¥ {showPrice(transactionData?.total_tax_10)}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={() => {
                    navigate(`/cashier/dashboard/categories/${transactionData._id}?type=edit-sales`)
                    closeModalRef.current.click();
                    // setEditModal(true);
                  }}
                >
                  {t("btn_edit")}
                </button>
                <ReactToPrint
                  trigger={() => (
                    <button type="button" className="btn btn-primary" style={{ width: "100px" }}>
                      {t("label_reprint")}
                    </button>
                  )}
                  content={() => printSlipRef.current}
                  pageStyle="@media print {.no-print {display: none;}}"
                />
                <div style={{ display: 'none' }} >
                  <div ref={printSlipRef} className="container d-flex justify-content-center align-items-center">
                    {/* <OrderReceipt orderId={params.orderId} ref={componentRef}/> */}
                    <div dangerouslySetInnerHTML={{ __html: transactionData?.receiptHTML ? transactionData?.receiptHTML : "N/A" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={addSlipChanges.handleSubmit}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content" style={{ borderRadius: "10px" }}>
                <div className="modal-header d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-icon"
                    onClick={() => {
                      setEditModal(false);
                      setIsDirty(true);
                      addSlipChanges.resetForm();
                    }}
                  >
                    <i className="ti ti-arrow-left"></i>
                  </button>
                  <h1 className="modal-title fs-5 text-center w-100" id="exampleModalLabel">
                    {t('label_slip_number')} : {String(transactionData?.slip_number)?.padStart(4, "0") || ""}
                  </h1>
                  <button
                    type="button"
                    className="btn btn-icon"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="close_modal"
                    ref={closeModalRef}
                    onClick={() => {
                      setEditModal(false);
                      setIsDirty(true);
                      // addSlipChanges.resetForm();
                    }}
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>

                <div className="modal-body p-0" ref={printSlipRef}>
                  <div className="sales-slip-details-box">
                    <div className="sales list-info" style={{ width: "100%", borderRight: "none" }}>
                      <ul>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_person_in_charge")}</span>
                          </div>
                          <div className="list-info-right">
                            <select className="form-control cp" name="cashier_id" value={addSlipChanges.values.cashier_id} onChange={(e) => { addSlipChanges.handleChange(e); setIsDirty(false) }} onBlur={addSlipChanges.handleBlur}>
                              {staffArray?.map((item, index) => {
                                return (
                                  <option value={item._id} key={index} selected={transactionData.cashier_id._id === item._id ? true : false}>
                                    {TrimText(item.name, 25)}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_table_no")}</span>
                          </div>
                          <div className="list-info-right">
                            <select className="form-control cp" name="table_id" value={addSlipChanges.values.table_id} onChange={(e) => { addSlipChanges.handleChange(e); setIsDirty(false) }} onBlur={addSlipChanges.handleBlur}>
                              {tableListArray?.map((item, index) => {
                                return (
                                  <option value={item._id} key={index} selected={transactionData.tableDetail._id === item._id ? true : false}>
                                    {item.table_number}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_no_of_people")}</span>
                          </div>
                          <div className="list-info-right">
                            <div className="d-flex flex-column gap-2 men-women-input-edit">
                              <div className="form-group d-flex align-items-center gap-2">
                                <label> {t("label_all")} </label>
                                <span>
                                  {/* : {transactionData?.number_of_persons || 0} */}
                                  <input
                                    type="number"
                                    className="form-control no_spring_input"
                                    name="number_of_persons"
                                    value={addSlipChanges?.values?.number_of_persons}
                                    onChange={(e) => {
                                      setIsDirty(false);
                                      addSlipChanges.handleChange(e);
                                    }}
                                    onBlur={addSlipChanges.handleBlur}
                                  />
                                </span>
                              </div>
                              <div className="form-group d-flex align-items-center gap-2">
                                <label>{t("men")}</label>
                                <input
                                  type="number"
                                  className="form-control no_spring_input"
                                  name="men"
                                  value={addSlipChanges?.values?.men}
                                  onChange={(e) => {
                                    setIsDirty(false);
                                    addSlipChanges.handleChange(e);
                                    if (e.target.value && !isNaN(e.target.value)) {
                                      const value = Number(e.target.value);
                                      const totalValue = addSlipChanges?.values?.number_of_persons;
                                      const finalValue = totalValue - value;
                                      if (finalValue >= 0) {
                                        addSlipChanges.setFieldValue("women", totalValue - value);
                                      } else {
                                        addSlipChanges.setFieldValue("women", 0);
                                      }
                                    }
                                  }}
                                  onBlur={addSlipChanges.handleBlur}
                                />

                                {/* <input type='text' className='form-control'/> */}
                              </div>
                              <div className="form-group d-flex align-items-center gap-2">
                                <label>{t("women")}</label>
                                <input
                                  type="number"
                                  className="form-control no_spring_input"
                                  name="women"
                                  value={addSlipChanges?.values?.women}
                                  onChange={(e) => {
                                    addSlipChanges.handleChange(e);
                                    setIsDirty(false)
                                    if (e.target.value && !isNaN(e.target.value)) {
                                      const value = Number(e.target.value);
                                      const totalValue = addSlipChanges?.values?.number_of_persons;
                                      const finalValue = totalValue - value;
                                      if (finalValue >= 0) {
                                        addSlipChanges.setFieldValue("men", totalValue - value);
                                      } else {
                                        addSlipChanges.setFieldValue("men", 0);
                                      }
                                    }
                                  }}
                                  onBlur={addSlipChanges.handleBlur}
                                />
                              </div>
                            </div>
                            <CustomError name="men" form={addSlipChanges} className="text-danger" />
                            <CustomError name="women" form={addSlipChanges} className="text-danger" />
                            <CustomError name="number_of_persons" form={addSlipChanges} className="text-danger" />
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_choose_payment_method")}</span>
                          </div>
                          <div className="list-info-right">
                            <select
                              className="form-control cp"
                              name="payment_method"
                              value={addSlipChanges.values.payment_method}
                              onChange={(e) => { addSlipChanges.handleChange(e); setIsDirty(false) }}
                              onBlur={addSlipChanges.handleBlur}
                            >
                              {PAYMENT_METHOD_TYPES_TRANSACTION.map((item, index) => {
                                return (
                                  <option value={item.value} key={index} selected={transactionData.payment_method === item.value ? true : false}>
                                    {t(item.label)}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </li>

                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_date_time")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>{formateDate(transactionData?.createdAt, "DD/MM/YYYY", true, false)}</span>
                          </div>
                        </li>
                        <li>
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_type")}</span>
                          </div>
                          <div className="list-info-right">
                            <select className="form-control" name="type" value={addSlipChanges.values.type} onChange={(e) => { addSlipChanges.handleChange(e); setIsDirty(false) }} onBlur={addSlipChanges.handleBlur}>
                              <option value="restaurant_order" selected={transactionData.type === "restaurant_order" ? true : false}>
                                {t("label_sales")}
                              </option>
                              <option value="cancelled" selected={transactionData.type === "cancelled" ? true : false}>
                                {t("label_cancelled")}
                              </option>
                            </select>
                          </div>
                        </li>
                        <li className="list-border-none">
                          <div className="list-info-left">
                            <span style={{ fontSize: "18px", fontWeight: 600, color: "black" }}>{t("label_sales")}</span>
                          </div>
                        </li>
                        <li className="list-border-none">
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_sub_total")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>¥ {showPrice(transactionData?.subtotal)} </span>
                          </div>
                        </li>
                        <li className="list-border-none">
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_discount_if_applicable")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>
                              {transactionData?.gift_card_applied || transactionData?.coupon_applied
                                ? `¥ ${showPrice(transactionData?.great_grand_total - transactionData?.amount_after_discount)}`
                                : 0}
                            </span>
                          </div>
                        </li>
                        <li className="list-border-none">
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_consumption_tax")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>¥ {showPrice(transactionData?.tax_price || 0)}</span>
                          </div>
                        </li>
                        {transactionData?.service_charge  > 0 &&
                         <li className="list-border-none">
                         <div className="list-info-left">
                           <span style={{ color: "black" }}>{t("service_charge")}</span>
                         </div>
                         <div className="list-info-right">
                           <span>¥ {showPrice(transactionData?.service_charge || 0)}</span>
                         </div>
                       </li>

                        }
                       
                        <li className="list-border-none">
                          <div className="list-info-left">
                            <span style={{ color: "black" }}>{t("label_total_capitalize")}</span>
                          </div>
                          <div className="list-info-right">
                            <span>
                              <b>¥ {showPrice(transactionData?.amount_after_discount || 0, 3)}</b>
                            </span>
                            <br></br>
                            <span>
                              8% {t("tax_on_text")} ¥ {showPrice(transactionData?.total_tax_8)}
                            </span>
                            <br></br>
                            <span>
                              10% {t("tax_on_text")} ¥ {showPrice(transactionData?.total_tax_10)}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className='p-3'>
                                  <textarea className='form-control' style={{ height: '100px' }} placeholder='Comments'></textarea>
                              </div> */}
                </div>
                <div className="modal-footer justify-content-center">
                  <button type="submit" className="btn btn-primary" style={{ width: "850px" }} disabled={isDirty || (JSON.stringify(defaultOrderData) === JSON.stringify(addSlipChanges?.values))}>
                    {t("btn_save_changes")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
        {/* </div> */}
      </div>

      {/* model for authenticate the user  */}

      <div className="modal fade modal-cashier" id="modal-users-single1" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">

              <h1 className="modal-title fs-5 text-center w-100" id="exampleModalLabel">
                {t("label_authenticate_user")}
              </h1>
              <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close" ref={closePasswordRef}>
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="user-enter-password ">
                <div className="list-user-box">
                  <figure className="avatar avatar-md">
                    <img src={selectedStaff && selectedStaff.profile_image ? selectedStaff?.profile_image : DefaultProfileImg} alt="Admin" />
                  </figure>
                  <h4>{selectedStaff && selectedStaff?.first_name}</h4>
                </div>
                <form onSubmit={(e) => {
                  e.preventDefault(); // Prevent the default form submission behavior
                  formik.handleSubmit(); // Trigger Formik's submit handler
                }}>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      {t("label_password")}
                      <span>*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type={!show === true ? "text" : "password"}
                        className="form-control border-end-0"
                        id="password"
                        placeholder={t("placeholder_password")}
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <span
                        className="input-group-text cp restaurant-password-toggle"
                        onClick={() => {
                          handleshow();
                        }}
                      >
                        {!show === true ? <i className="ti ti-eye"></i> : <i className="ti ti-eye-off"></i>}
                      </span>
                    </div>
                    <CustomError name="password" form={formik} className="text-danger" />
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    {t("btn_submit")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
