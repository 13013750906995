import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { login } from "../../services/auth.services";
import { Link } from "react-router-dom";
import {
    getSessionKey,
    handleNavigation,
    handleServerValidations,
    removeSessionKey,
    setLocalKey,
    setSessionKey,
} from "../../../../utils/commonfunction";
import { SWAL_SETTINGS, COOKIES_EXPIRATION, ROLE, REST_LOGIN_ERROR_TYPES, PHONE_NO_LENGTH, INPUT_LENGTH_15, PLAN_TYPES, PLAN_DURATION_TYPES, TEXTAREA_MAX_LENGTH } from "../../../../utils/Constants";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import CustomError from "../../../../utils/customError";
import { useValidationSchemas } from "../../validator/validation";
import logo from '../../../../assets/restaurant/images/logo.svg';
import { useSelector } from "react-redux";
import PhoneInput from "../../../../utils/PhoneInput";
import { DatePicker } from "rsuite";


const RestaurantRegister = () => {
    const { t } = useTranslation()
    const { registerValidationSchema } = useValidationSchemas();
    const navigate = useNavigate();
    const myGlobalData = useSelector((state) => state?.globalData?.data);
    const [getGlobalData, setGlobalData] = useState({});

    useEffect(() => {
        if (myGlobalData) {
            const settingsData = JSON.parse(myGlobalData);
            setGlobalData(settingsData);
        }
    }, [myGlobalData]);

    const yearlyEnrollCost = getGlobalData?.yearly_restaurant_enrollment_cost;
    const monthlyEnrollCost = getGlobalData?.monthly_restaurant_enrollment_cost;

    /** Remember last logged-in user >>>> */

    const formik = useFormik({
        initialValues: {
            rest_Id: "",
            email: "",
            restaurant_name: "",
            phone_number: "",
            company_name: "",
            company_address: "",
            shipping_address: "",
            representative_name: "",
            contractor_name: "",
            plan_name: "",
            expiry_date: "",
            contract_plan_duration_type: PLAN_DURATION_TYPES.MONTHLY,
            membership_cost: 0,
        },
        validationSchema: registerValidationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            let formData = new FormData();
            formData.append("rest_Id", values.id);
            // formData.append("primary_id", values.userId);
            formData.append("email", values.email);
            formData.append("restaurant_name", values.restaurant_name);
            formData.append("phone_number", values.phone_number);
            formData.append("company_address", values.company_address);
            formData.append("company_name", values.company_name);
            formData.append("shipping_address", values.shipping_address);
            formData.append("representative_name", values.representative_name);
            formData.append("contractor_name", values.contractor_name);
            formData.append("plan_name", values.plan_name);
            // formData.append("restaurant_id", location[5]);
            if (values.plan_name === PLAN_TYPES.PREMIUM_PLAN) {
                formData.append("contract_plan_duration_type", values.contract_plan_duration_type);
            } else {
                formData.append("expiry_date", values.expiry_date);
            }
            if (values.plan_name === PLAN_TYPES.PREMIUM_PLAN) {
                if (values?.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY) {
                    // formData.append("membership_cost", yearlyEnrollCost);
                } else {
                    // formData.append("membership_cost", monthlyEnrollCost);
                }
            } else {
                formData.append("membership_cost", values?.membership_cost);
            }


            login(formData)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        navigate(`/restaurant/login`);
                    } else {
                        let message = handleServerValidations(response);
                        /** Handling different types of server errors */
                        const supportUrlConditions = [REST_LOGIN_ERROR_TYPES.ACCOUNT_IS_BLOCKED, REST_LOGIN_ERROR_TYPES.SUBSCRIPTION_EXPIRED, REST_LOGIN_ERROR_TYPES.ACCOUNT_NOT_ENROLLED, REST_LOGIN_ERROR_TYPES.PASSWORD_NOT_SET_YET];
                        if (supportUrlConditions.indexOf(response.error_type) !== -1) {
                            message += `<a class="d-block mt-2 no-decoration link-text" href="/support/${response.error_type}">${t('btn_text_contact_us')}</a>`;
                        }
                        Swal.fire({
                            icon: "error",
                            html: message,
                            ...SWAL_SETTINGS
                        });
                    }
                })
                .catch((error) => {
                    console.log("error===>", error)
                })
        }
    });



    return (
        <div className="register-wrap">
            <div className="register-wrap-in">
                <div className="login-logo">

                    <img src={logo} alt="" />

                </div>
                <div className="login-form-wrap">
                    <div className="login-form-header">
                        <h4>{t('register_text')}</h4>
                        <p>{t('register_para_text')}</p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="login-form">
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <label htmlFor="rest_Id" className="form-label">
                                        {t("label_id")}:<span className="requirestar">*</span>{" "}
                                    </label>
                                    <input
                                        name="rest_Id"
                                        id="rest_Id"
                                        type="text"
                                        disabled={true}
                                        // onBlur={formik.handleBlur}
                                        // value={restData.id}
                                        className="form-control"
                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError name="rest_Id" form={formik} />
                                    </span>
                                </div>

                                <div className="col-6 mb-3">
                                    <label htmlFor="restaurant_name" className="form-label">{t('label_restaurant_name')}<span>*</span></label>
                                    <input
                                        type="restaurant_name"
                                        className="form-control"
                                        id="restaurant_name"
                                        name="restaurant_name"
                                        placeholder={t('placeholder_restaurant_name')}
                                        value={formik?.values?.restaurant_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError name="restaurant_name" form={formik} />
                                    </span>

                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="email" className="form-label">{t('label_email_address')}<span>*</span></label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder={t('placeholder_email_address')}
                                        value={formik?.values?.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError name="email" form={formik} />
                                    </span>
                                </div>
                                <div className="col-6 mb-3">
                                    <label className="form-label" htmlFor="phone_number">
                                        {t("label_phone_number")}:<span className="requirestar">*</span>{" "}
                                    </label>
                                    <PhoneInput
                                        name="phone_number"
                                        id="phone_number"
                                        onChange={(e) => {
                                            formik.setFieldValue("phone_number", e?.phone_number);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone_number}
                                        className="form-control"
                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError name="phone_number" form={formik} shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                                    </span>
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="company_name" className="form-label">
                                        {t("label_company")} {t("list_heading_name")}:<span className="requirestar">*</span>{" "}
                                    </label>
                                    <input
                                        name="company_name"
                                        id="company_name"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.company_name}
                                        className="form-control"
                                        placeholder={t("company_name_placeholder")}
                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError shortCodes={[INPUT_LENGTH_15]} name="company_name" form={formik} />
                                    </span>
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="representative_name" className="form-label">
                                        {t("label_representative_name")}:<span className="requirestar">*</span>{" "}
                                    </label>
                                    <input
                                        name="representative_name"
                                        id="representative_name"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.representative_name}
                                        className="form-control"
                                        placeholder={t("representative_name_placeholder")}
                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError shortCodes={[INPUT_LENGTH_15]} name="representative_name" form={formik} />
                                    </span>
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="contractor_name" className="form-label">
                                        {t("label_contractor_name")}:<span className="requirestar">*</span>{" "}
                                    </label>
                                    <input
                                        name="contractor_name"
                                        id="contractor_name"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.contractor_name}
                                        className="form-control"
                                        placeholder={t("contractor_name_placeholder")}
                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError shortCodes={[INPUT_LENGTH_15]} name="contractor_name" form={formik} />
                                    </span>
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="plan_name" className="form-label">
                                        {t("label_plan_name")}:<span className="requirestar">*</span>{" "}
                                    </label>
                                    <div className="">
                                        <select name="plan_name" id="plan_name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.plan_name} className="form-control cp">
                                            <option className="cp" value="">
                                                {t("input_select_text")}
                                            </option>
                                            {Object.keys(PLAN_TYPES).map((item, index) => {
                                                return (
                                                    <option className="cp" key={index} value={PLAN_TYPES[item]}>
                                                        {t(`plan_type_${item.toLowerCase()}`)}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <span className="text-danger d-flex text-left">
                                        <CustomError name="plan_name" form={formik} />
                                    </span>
                                </div>

                                {formik.values.plan_name === PLAN_TYPES.DEMO ? (
                                    <div className="col-6 mb-3">
                                        <label htmlFor="expiry_date" className="form-label">
                                            {t("label_expiry_date")}:<span className="requirestar">*</span>{" "}
                                        </label>
                                        <DatePicker
                                            oneTap={true}
                                            classPrefix="restaurant"
                                            placeholder={t("placeholder_choose_date")}
                                            placement="autoVertical"
                                            format="dd/MM/yyyy"
                                            cleanable={false}
                                            editable={false}
                                            name="expiry_date"
                                            // caretAs={CustomBase64Caret}
                                            // shouldDisableDate={(date) => isBefore(date, new Date(yesterday))}
                                            // onChange={(e) => {
                                            //     formik.setFieldTouched("expiry_date", true);
                                            //     formik.setFieldValue("expiry_date", e);
                                            // }}
                                            // value={new Date(formik.values.expiry_date)}
                                            // onBlur={formik.handleBlur}
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="expiry_date" form={formik} />
                                        </span>
                                    </div>
                                ) : null}

                                {formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN ? (
                                    <>
                                        <div className="col-6 mb-3">
                                            <label htmlFor="contract_plan_duration_type" className="form-label">
                                                {t("contract_plan_duration_type")}:<span className="requirestar">*</span>{" "}
                                            </label>
                                            <div className="d-flex gap-5">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input cp"
                                                        type="radio"
                                                        name="contract_plan_duration_type"
                                                        value={PLAN_DURATION_TYPES.MONTHLY}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.MONTHLY}
                                                    />{" "}
                                                    {t("input_radio_monthly")}
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input cp"
                                                        type="radio"
                                                        name="contract_plan_duration_type"
                                                        value={PLAN_DURATION_TYPES.YEARLY}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY}
                                                    />{" "}
                                                    {t("input_radio_yearly")}
                                                </div>
                                            </div>
                                            <span className="text-danger d-flex text-left">
                                                <CustomError name="contract_plan_duration_type" form={formik} />
                                            </span>
                                        </div>

                                        <div className="col-6 mb-3">
                                            <label htmlFor="membership_cost" className="form-label">
                                                {t("label_membership_cost")}:<span className="requirestar">*</span>{" "}
                                            </label>

                                            <div className="input-group">
                                                <span class="input-group-text" id="basic-addon1">¥</span>

                                                <input
                                                    name="membership_cost"
                                                    id="membership_cost"
                                                    type="text"
                                                    disabled={formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN ? true : false}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                        formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN
                                                            ? formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY
                                                                ? yearlyEnrollCost
                                                                : monthlyEnrollCost
                                                            : formik.values.membership_cost
                                                    }
                                                    className="form-control rounded-start-0"
                                                />
                                            </div>
                                            <span className="text-danger d-flex text-left">
                                                <CustomError name="membership_cost" form={formik} />
                                            </span>
                                        </div>
                                    </>
                                ) : null}

                                <div className="col-6 mb-3">
                                    <label htmlFor="company_address" className="form-label">
                                        {t("label_company")} {t("label_address")}:<span className="requirestar">*</span>{" "}
                                    </label>
                                    <textarea
                                        name="company_address"
                                        id="company_address"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.company_address}
                                        className="form-control"
                                        placeholder={t("company_address_placeholder")}
                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError shortCodes={[TEXTAREA_MAX_LENGTH]} name="company_address" form={formik} />
                                    </span>
                                </div>

                                <div className="col-6 mb-3">
                                    <label htmlFor="shipping_address" className="form-label">
                                        {t("label_shipping")} {t("label_address")}:<span className="requirestar">*</span>{" "}
                                    </label>
                                    <textarea
                                        name="shipping_address"
                                        id="shipping_address"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.shipping_address}
                                        className="form-control"
                                        placeholder={t("shipping_address_placeholder")}
                                    />
                                    <span className="text-danger d-flex text-left">
                                        <CustomError shortCodes={[TEXTAREA_MAX_LENGTH]} name="shipping_address" form={formik} />
                                    </span>
                                </div>

                            </div>

                            <button className="btn btn-primary w-100 mt-4 justify-content-center">{t('label_register')}</button>
                            <div className="login-cta-bottom text-center mt-3">

                                <p>{t("already_have_account_text")}
                                    <Link className="link-text ms-1" to="/restaurant/login">
                                        {t("link_login")}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RestaurantRegister