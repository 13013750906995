import { createSlice } from "@reduxjs/toolkit";

export const RestSettingsSlice = createSlice({
  name: "restSettings",
  initialState: {
    data: null , 
    profileData : null
  },
  reducers: {
    addRestData: (state, action) => {
      state.data = action.payload;
    },
    addRestProfileData: (state, action) => {
      state.profileData = action.payload;
    },
}
});

export const { addRestData , addRestProfileData} = RestSettingsSlice.actions;
export default RestSettingsSlice.reducer;
