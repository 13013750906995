import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  ATLEAST_ONE_CAPITAL_REGEX,
  ATLEAST_ONE_NUMBER_REGEX,
  ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  ATLEAST_ONE_SMALL_REGEX,
  PHONE_NO_LENGTH,
  EMAIL_REGEX,
  TITLE_MAX_LIMIT,
} from "../../../utils/Constants"
import { PHONE_VALIDATION } from "../../../utils/commonValidations";

export const useValidationSchemas = () => {
  const { t } = useTranslation();

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t("label_email_invalid_format_error"))
      .test('no-uppercase', t("label_email_uppercase_error"), function (value) {
        if (value) {
          return !/[A-Z]/.test(value);
        }
        return true;
      })
      .required(t("label_email_required_error")),
    password: Yup.string().required(t("validation_password_required"))
  })

  const registerValidationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t("label_email_invalid_format_error"))
      .test('no-uppercase', t("label_email_uppercase_error"), function (value) {
        if (value) {
          return !/[A-Z]/.test(value);
        }
        return true;
      })
      .required(t("label_email_required_error")),
    restaurant_name: Yup.string().required(t("label_restaurants_error"))
  })

  const forgetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("label_email_invalid_format_error")
      .test('no-uppercase', "label_email_uppercase_error", function (value) {
        if (value) {
          return !/[A-Z]/.test(value);
        }
        return true;
      })
      .required("label_email_required_error"),
  })

  const resetPswValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t("label_new_password_error"))
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        t("password_validation_lowercase")
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        t("Password_Validation_uppercase")
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        t("Password_Validation_number")
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        t("Password_Validation_special_char")
      )
      .min(PASSWORD_MIN_LENGTH, t("Password_Validation_minimum_length"))
      .max(
        PASSWORD_MAX_LENGTH,
        t("Password_Validation_maximum_length")
      ),
    confirmPassword: Yup.string()
      .required(t("label_confirm_password_error"))
      .oneOf([Yup.ref("newPassword"), null], t("password_validation_does_not_match")),
  });
  const otpValidationSchema = Yup.object().shape({
    otp: Yup.string()
      .required(t("validation_OTP_is_required"))
      .length(4, t("otp_error_msg"))
      .matches(/^\d{4}$/, t("invalid_otp")),
  });

  const restaurantProfileValidationSchema = Yup.object({
    restaurantName: Yup.string().required('label_restaurants_error'),
    restaurantAddress: Yup.string().required('restaurant_address_error'),
    city: Yup.string().required('label_city_error'),
    zipCode: Yup.string().required('zip_code_error'),
    website: Yup.string().url('invalid_web_url'),
    cuisineType: Yup.array().min(1, 'label_cuisine_type_error').required('label_cuisine_type_error'),
    openingTime: Yup.string().required('opening_time_error'),
    closingTime: Yup.string().required('closing_time_error').test('greater_time', 'rest_validation_closing_time_should_be_greater_than_opening_time', function (value) {
      // const [opHours, opMins] = this.parent?.openingTime?.split(":");
      // const [closeHours, closeMins] = value?.split(":");
      // if(opHours > closeHours){
      //   return false;
      // }else if(opHours === closeHours){
      //   if(opMins >= closeMins){
      //     return false;
      //   }
      // }
      // return true;
      if (this.parent?.openingTime && value) {
        try {
          const openDate = new Date(this.parent?.openingTime);
          const closeDate = new Date(value);
          return closeDate.getTime() > openDate.getTime();
        } catch (err) {
          console.log("err", err);
        }
      }
      return true;
    }),
  });

  const profileValidationSchema = Yup.object({
    email: Yup.string().matches(EMAIL_REGEX, 'invalid_email').required('label_email_error'),
    opening: Yup.number().oneOf([0, 1], 'Value must be 0 or 1').required('This field is required'),
    settlement: Yup.number().oneOf([0, 1], 'Value must be 0 or 1').required('This field is required'),
    cancel_sales: Yup.number().oneOf([0, 1], 'Value must be 0 or 1').required('This field is required'),
    edit_sales: Yup.number().oneOf([0, 1], 'Value must be 0 or 1').required('This field is required'),
  });


  const CashierPasswordValidationSchema = Yup.object({
    cashier_password: Yup.string()
      .required("validation_password_required")
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        "password_validation_lowercase"
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        "Password_Validation_uppercase"
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        "Password_Validation_number"
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        "Password_Validation_special_char"
      )
      .min(PASSWORD_MIN_LENGTH, "Password_Validation_minimum_length")
      .max(
        PASSWORD_MAX_LENGTH,
        "Password_Validation_maximum_length"
      )
  });


  const categoryValidationSchema = Yup.object({
    name: Yup.string().required("category_name_error"),
    name_ja: Yup.string().required("category_name_ja_error"),
    tax_type: Yup.string().required("tax_type_error"),
    color: Yup.string().required("color_error"),
    short_description: Yup.string().required("label_short_description_error"),
    short_description_ja: Yup.string().required("label_short_description_japanese_error"),
  });


  const menuItemsValidationSchema = Yup.object().shape({
    item_name: Yup.string()
      .required('item_name_required'),
    item_name_ja: Yup.string()
      .required('item_name_ja_required'),
    category_id: Yup.string()
      .required('category_id_required'),
    item_code: Yup.string()
      .required('item_code_required'),
    short_description: Yup.string()
      .required('short_description_required'),
    short_description_ja: Yup.string()
      .required('short_description_ja_required'),
    price: Yup.number()
      .required('price_required')
      .moreThan(0, 'price_more_than_zero'),
    cost_price: Yup.number()
      .required('cost_price_required')
      .moreThan(0, 'cost_price_more_than_zero'),
    consumption_tax_rate: Yup.number()
      .required('consumption_tax_rate_required')
      .moreThan(0, 'consumption_tax_rate_more_than_zero'),
    spice_level_needed: Yup.boolean()
      .required('spice_level_needed_required'),
    image: Yup.mixed()
      .required('label_image_error')
      .test(
        'fileType',
        'supported_file_error',
        value =>
          !value ||
          typeof value === 'string' ||
          ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(value.type)
      )
      .test(
        'fileSize',
        'image_max_size_error',
        value =>
          !value ||
          typeof value === 'string' ||
          value.size <= 1.5 * 1024 * 1024
      )
      .test(
        'isValidUrlOrFile',
        'invalid_url_error',
        value => !value || typeof value !== 'string' || /^(ftp|http|https):\/\/[^ "]+$/.test(value)
      ),
    type: Yup.string()
      .required('type_required'),
    addons: Yup.array().when('type', ([type], schema) => {
      return type === "set_of_items" ? Yup.array().min(1, 'addons_minimum_one_required').of(
        Yup.object().shape({
          option_id: Yup.string().required('addon_required_error'),
          item_id: Yup.string().required('addon_item_required_error'),
        })
      ) : schema;
    }),
    item_variation: Yup.string()
    .required('item_variation_required'),


  });


  const staffValidationSchema = Yup.object({
    employee_id: Yup.string().trim().required("employee_id_error"),
    first_name: Yup.string().trim().required("label_first_name_error"),
    last_name: Yup.string().trim().required("label_last_name_error"),
    staff_joining_date: Yup.string().trim().required("label_joining_date_error"),
    staff_state: Yup.string(),
    staff_zip_code: Yup.string(),
    staff_city: Yup.string(),
    address: Yup.string().trim(),
    email: Yup.string().trim().required('label_email_error').matches(EMAIL_REGEX, 'invalid_email'),
    phone_number: Yup.string()
      .test('phone_required', 'label_phone_number_required_error', (value) => {
        console.log("value", value)
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length;
      }
      )
      .test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && /^\d+$/.test(phoneNumber);
      }
      )
      .test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
      }
      )
      .test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
      }
      ),
    password: Yup.string()
      .required(t("validation_password_required"))
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        t("password_validation_lowercase")
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        t("Password_Validation_uppercase")
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        t("Password_Validation_number")
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        t("Password_Validation_special_char")
      )
      .min(PASSWORD_MIN_LENGTH, t("Password_Validation_minimum_length"))
      .max(
        PASSWORD_MAX_LENGTH,
        t("Password_Validation_maximum_length")
      ),
  });


  const editStaffValidationSchema = Yup.object({
    employee_id: Yup.string().trim().required("employee_id_error"),
    first_name: Yup.string().trim().required("label_first_name_error"),
    last_name: Yup.string().trim().required("label_last_name_error"),
    staff_joining_date: Yup.string().trim().required("label_joining_date_error"),
    staff_state: Yup.string(),
    staff_zip_code: Yup.string(),
    staff_city: Yup.string(),
    address: Yup.string().trim(),
    email: Yup.string().trim().required('label_email_error').matches(EMAIL_REGEX, 'invalid_email'),
    phone_number: Yup.string()
      .test('phone_required', 'label_phone_number_required_error', (value) => {
        console.log("value", value)
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length;
      }
      )
      .test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && /^\d+$/.test(phoneNumber);
      }
      )
      .test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
      }
      )
      .test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
      }
      ),
    password: Yup.string()
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        t("password_validation_lowercase")
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        t("Password_Validation_uppercase")
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        t("Password_Validation_number")
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        t("Password_Validation_special_char")
      )
      .min(PASSWORD_MIN_LENGTH, t("Password_Validation_minimum_length"))
      .max(
        PASSWORD_MAX_LENGTH,
        t("Password_Validation_maximum_length")
      ),
  });


  const tableValidationSchema = Yup.object({
    table_number: Yup.string().trim().required("table_number_error").max(TITLE_MAX_LIMIT, "max_character_length_error"),

    capacity: Yup.string().trim()
      .required("table_capacity_error")
      .test('capacity_digit_error', 'number_err_msg', (value) => {
        const capacity = value ? parseFloat(value) : null;
        return capacity !== null && capacity >= 0; // Check if the value is non-negative
      })
      .test('capacity_digit_error', 'label_capacity_digits_error', (value) => {
        const capacity = value ? parseFloat(value) : null;
        return capacity !== null && Number.isInteger(capacity); // Check if the value is an integer
      }),

    shape: Yup.string().trim().required('table_shape_error')
  });

  const serviceChargeValidationSchema = Yup.object().shape({
    service_charge: Yup.string()
      .trim()
      .required("service_charge_required")  // Required field
      .test('is-non-negative', 'service_charge_negative_error', (value) => {
        const serviceCharge = value ? parseFloat(value) : null;
        return serviceCharge !== null && serviceCharge >= 0 && Number.isInteger(serviceCharge);  // Check non-negative and integer
      })
      .test('is-within-range', 'service_charge_limit_error', (value) => {
        const serviceCharge = value ? parseInt(value) : null;
        return serviceCharge !== null && serviceCharge >= 0 && serviceCharge <= 100;  // Check within range 0 to 100
      })
  });



  const addResevationValidationSchema = Yup.object().shape({
    table_id: Yup.string().trim().required("table_error"),
    table_number: Yup.string().trim(),
    number_of_guest: Yup.string().trim()
    .required("table_capacity_error")
    .test('capacity_digit_error', 'number_err_msg', (value) => {
      const capacity = value ? parseFloat(value) : null;
      return capacity !== null && capacity > 0; // Check if the value is non-negative
    })
    .test('capacity_digit_error', 'label_capacity_digits_error_guests', (value) => {
      const capacity = value ? parseFloat(value) : null;
      return capacity !== null && Number.isInteger(capacity); // Check if the value is an integer
    }),
    customer_name: Yup.string().trim().required("label_customer_name_error"),
    customer_email: Yup.string().trim().required('label_email_error').matches(EMAIL_REGEX, 'invalid_email'),
    customer_phone_number: Yup.string()
      .test('phone_required', 'label_phone_number_required_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length;
      }
      )
      .test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && /^\d+$/.test(phoneNumber);
      }
      )
      .test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
      }
      )
      .test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
      }
      ),

    reservation_date: Yup.string().trim().required("label_date_error"),
    start_time: Yup.string().trim().required("label_start_time_error"),
    end_time: Yup.string()
  .required('label_end_time_error')
  .test('greater_time', 'rest_validation_end_time_should_be_greater_than_start_time', function (value) {
    if (this.parent?.start_time && value) {
      try {
        const openDate = new Date(this.parent?.start_time);
        const closeDate = new Date(value);
        openDate.setSeconds(0);
        openDate.setMilliseconds(0);
        closeDate.setSeconds(0);
        closeDate.setMilliseconds(0);
        return closeDate.getTime() > openDate.getTime();
      } catch (err) {
        console.log("err", err);
      }
    }
    return true;
  }),
  });


  const editSlipValidationSchema = Yup.object().shape({
    transaction_id: Yup.string().trim().required("Transaction ID is required"),

    //validation for number of persons
    number_of_persons: Yup.number()
    .required("All count is required")
    .integer("All count must be a whole number")
    .min(0, "All count cannot be negative")
    .test(
      "all-valid",
      "All count cannot be in decimal",
      (value) => Number.isInteger(value) && value >= 0
    ),
  
    // Validation for men count
    men: Yup.number()
      .required("Men count is required")
      .integer("Men count must be a whole number")
      .min(0, "Men count cannot be negative")
      .max(Yup.ref("number_of_persons"), "Men count cannot exceed total number of persons")
      .test(
        "men-valid",
        "Men count cannot be in decimal",
        (value) => Number.isInteger(value) && value >= 0
      ),
  
    // Validation for women count
    women: Yup.number()
      .required("Women count is required")
      .integer("Women count must be a whole number")
      .min(0, "Women count cannot be negative")
      .max(Yup.ref("number_of_persons"), "Women count cannot exceed total number of persons")
      .test(
        "women-valid",
        "Women count cannot be in decimal",
        (value) => Number.isInteger(value) && value >= 0
      ),
  
    payment_method: Yup.string().trim().required("Payment method is required"),
    type: Yup.string().trim().required("Type is required"),
    cashier_id: Yup.string().trim().required("Cashier ID is required"),
    table_id: Yup.string().trim().required("Table ID is required"),
    comment: Yup.string()
    .trim()
    .max(500, "Comment must not exceed 500 characters")
    .optional(),

    ordered_items: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number()
        .required("All count is required")
        .integer("All count must be a whole number")
        .min(0, "All count cannot be negative"),
        price: Yup.number()
        .required("All count is required")
        .min(0, "All count cannot be negative").test(
          "is-decimal",
          "Only two decimal places are allowed",
          (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
        ),
        temp_item_id : Yup.string().trim().required("Item id is required") , 
        order_id : Yup.string().trim().required("order id is required") , 
       
      }) , 
    ),
  });


  const validatePassword = Yup.object().shape({
    password: Yup.string()
      .required(t("validation_password_required")) 
  });
  
  
  
  
  





  // number_err_msg label_capacity_digits_error
  return {
    loginValidationSchema,
    registerValidationSchema,
    forgetPasswordValidationSchema,
    resetPswValidationSchema,
    otpValidationSchema,
    restaurantProfileValidationSchema,
    CashierPasswordValidationSchema,
    profileValidationSchema,
    categoryValidationSchema,
    menuItemsValidationSchema,
    staffValidationSchema,
    editStaffValidationSchema,
    tableValidationSchema,
    serviceChargeValidationSchema,
    addResevationValidationSchema, 
    editSlipValidationSchema, 
    validatePassword
  };
};


