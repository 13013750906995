import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as dashboardService from "../services/dashboard.services";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [showDashboardCount, setShowDashboardCount] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    dashboardService
      .Counts()
      .then((response) => {
        setShowDashboardCount(response && response.data ? response.data : {});
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  }, []);
  return (
    <>
      <div className="row row-sm animation_fade">
        <div className="col-sm-12 col-lg-12 col-xl-12">
          <div className="row row-sm">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                  <Link to={`/admin/restaurant-management/restaurant/list/1`}>
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp ">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" className="bi bi-people-fill" viewBox="0 0 16 16">
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">{t("sidebar_link_restaurant")}</label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">{showDashboardCount && showDashboardCount.users ? showDashboardCount.users : "0"}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                  <Link to={`/admin/restaurant-management/restaurant/list/1`}>
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" stroke="currentColor" stroke-width="0.6" class="bi bi-currency-yen" viewBox="0 0 16 16">
                          <path d="M8.75 14v-2.629h2.446v-.967H8.75v-1.31h2.445v-.967H9.128L12.5 2h-1.699L8.047 7.327h-.086L5.207 2H3.5l3.363 6.127H4.778v.968H7.25v1.31H4.78v.966h2.47V14h1.502z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">{t("label_total_payment")}</label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">{showDashboardCount && showDashboardCount.transactionTotals ? "¥ " + showDashboardCount.transactionTotals.greatGrandTotal : "¥ " + "0"}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                  <Link to={`/admin/restaurant-management/restaurant/list/1`}>
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" stroke="currentColor" stroke-width="0.6" class="bi bi-currency-yen" viewBox="0 0 16 16">
                          <path d="M8.75 14v-2.629h2.446v-.967H8.75v-1.31h2.445v-.967H9.128L12.5 2h-1.699L8.047 7.327h-.086L5.207 2H3.5l3.363 6.127H4.778v.968H7.25v1.31H4.78v.966h2.47V14h1.502z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">{t("label_revenue")}</label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">{showDashboardCount && showDashboardCount.transactionTotals ? "¥ " + showDashboardCount.transactionTotals.subtotal : "¥ " + "0"}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
