import React, { useState, useEffect , useMemo} from 'react';
import { CMSDetails } from '../services/website.services';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useParams } from "react-router-dom";
import { createMeta, setTitle } from '../../../utils/commonfunction';
import Page404 from './Page404';
import { LOADER_TIMEOUT_TIME } from '../../../utils/Constants';


const DynamicPages = () => {

    const params = useParams();
    const [loader, setLoader] = useState(true);
    const loc = useLocation();
    const [HTML, setHTML] = useState('');
    const [refreshOptions, setRefreshOptions] = useState(false);

  
    useEffect(() => {
        setLoader(true);
        CMSDetails(params?.slug).then((response) => {
            setTitle(response?.data?.title);
            createMeta(response?.data);
            setHTML(response?.data?.description);
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIMEOUT_TIME);
        }).catch((error) => {
            setLoader(false);
            console.log("error of terms&conditions", error);
        })
    }, [loc, params?.slug, refreshOptions]);
    useMemo(()=>{
        document.addEventListener('language_change',function(){
          setRefreshOptions(!refreshOptions);
        })
      });

    return (
        <>
            <div className="wraper-inner cpt">
                {loader ?
                    <div className="container py-4">
                        <Skeleton height={150} />
                        <Skeleton height={30} count={10} />
                    </div>
                    :
                    HTML?
                    <div className='container py-4' style={{ minHeight: '70vh' }} dangerouslySetInnerHTML={{ __html: `${HTML || "N/A"}` }}></div>
                    :<Page404 homePath='/'></Page404>
                }
            </div>
        </>
    )
}

export default DynamicPages;