import React, { useEffect, useLayoutEffect,useMemo } from "react";
import WebsiteHeader from "./header";
import WebsiteFooter from "./footer";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch} from "react-redux";
import { setGlobalLoader } from "../../../redux/slices/globalLoader";
import { addData } from "../../../redux/slices/globalDetails";
import { GlobalSettingsDetails } from "../services/website.services";
import { setTitle } from "../../../utils/commonfunction";

function WebsiteLayout() {
  const params = useParams();
  const loc = useLocation();
  const dispatch = useDispatch();
  
  useLayoutEffect(() => {
    Promise.all([import("../../../assets/restaurant/css/style.css"),import("../../../assets/website/css/style.css"), import("../../../assets/website/css/responsive.css")]).then(() => {
      dispatch(setGlobalLoader(false));
    });
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    setTimeout(() => {
      if(hash){
        const elem = document.querySelector(hash);
        if(elem){
          const scroll = elem.offsetTop;
          if(scroll){
            document.querySelector("html, body").scrollTop=scroll;
          }
        }
      }else{
        document.querySelector("html, body").scrollTop=0;
      }
    }, 50);
  }, [loc, params?.slug]);

  return (
    <div>
      <WebsiteHeader />
      <div className="inner-wraper">
        <Outlet />
      </div>
      <WebsiteFooter />
    </div>
  );
}

export default WebsiteLayout;
