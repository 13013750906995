import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAdded: false
};

const menuItemSlice = createSlice({
  name: 'menuItemDetails',
  initialState,
  reducers: {
    setIsAdded(state, action) {
      state.data = action.payload;
    }
  }
});

export const { setIsAdded} = menuItemSlice.actions;
export default menuItemSlice.reducer;