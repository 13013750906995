import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/sales-slip";
const options = {role: ROLE.RESTAURANT};


export const editSalesSlip = async (values) => {
  return await axiosInstance(options).post(`${path}/edit-sales-slip`, values);
}

export const salesSlipChangesList = async (values) => {
  return await axiosInstance(options).post(`${path}/sales-slip-changes-list`, values);
}

