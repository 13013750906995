import React, { useEffect, useMemo, useRef, useState } from 'react';
import card1 from '../../../assets/cashier/images/card-img.svg';
import card2 from '../../../assets/cashier/images/card2-img.svg';
import PayIcon from '../../../assets/cashier/images/pay-img.svg';
import visa from '../../../assets/cashier/images/visa.png';
import linepay from '../../../assets/cashier/images/line-pay.png';

// import HomeIcon from '../../../assets/cashier/images/home-img.svg';
// import InvoiceIcon from '../../../assets/cashier/images/invoice-img.svg';
// import ReprintIcon from '../../../assets/cashier/images/reprint-img.svg';
// import AmericanCard from '../../../assets/cashier/images/american-card.png';
// import amex from '../../../assets/cashier/images/amex.png';
// import diners from '../../../assets/cashier/images/diners.png';
// import JcbCard from '../../../assets/cashier/images/jcb-card.png';
// import Mastercard from '../../../assets/cashier/images/mastercard.png';
// import aupay from '../../../assets/cashier/images/au-pay.png';
// import merupay from '../../../assets/cashier/images/meru-pay.png';
// import paypay from '../../../assets/cashier/images/pay-pay.png';
// import rakutenpay from '../../../assets/cashier/images/rakuten-pay.png';
// import dbarai from '../../../assets/cashier/images/d-barai.png';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { detail, payByCash, PaymentMethodList } from '../services/table.services';
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES, ROLE, SWAL_SETTINGS } from '../../../utils/Constants';
import RecordNotFound from '../../restaurant/shared/recordNotFound';
import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import { DT, getLocalKey, getSubTotalQRApp, handleServerValidations, showPrice } from '../../../utils/commonfunction';

const keypadButtons = [
  '1', '2', '3',
  '4', '5', '6',
  '7', '8', '9',
  '0', '00', 'C'
];

export default function PaymentViaCash() {
  const [amountReceived, setAmountReceived] = useState(0);
  const [creditCardPaymentMethod, setCreditCardPaymentMethod] = useState([]);
  const [otherPaymentMethod, setOtherPaymentMethod] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const amountReceivedRef = useRef(null);
  const [tableDetail, setTableDetail] = useState({});
  const params = useParams();
  const [orderPriceDetail, setOrderPriceDetail] = useState(null);
  const restaurantData = getLocalKey(ROLE.CASHIER) ? JSON.parse(getLocalKey(ROLE.CASHIER)) : {};
  const cashierData = getLocalKey('cashierDetail') ? JSON.parse(getLocalKey('cashierDetail')) : {};
  const [couponCodeApplied, setCouponCodeApplied] = useState(false);
  const [giftCardApplied, setGiftCardApplied] = useState(false);
  const [loader, setLoader] = useState(false);


  const getTableDetail = (tableId) => {
    detail({ o_id: tableId })
      .then((response) => {
        setTableDetail(response?.data);
        if (response?.data?.is_available === false) {
          const combinedOrderedItems = [...response?.data?.activeOrder?.ordered_items];
          response?.data?.activeOrder?.subOrders.forEach(subOrder => {
            combinedOrderedItems.push(...subOrder.ordered_items);
          });
          if (response?.data?.activeOrder?.gift_card_applied) {
            setGiftCardApplied(true);
          }
          if (response?.data?.activeOrder?.coupon_code_applied) {
            setCouponCodeApplied(true);
          }
          handelOrderCalculations(response?.data?.activeOrder);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }


  const handelOrderCalculations = (orderDetail, coupon_code_applied) => {
    let data = {
      subtotal: 0,
      tax_price: 0,
      grand_total: 0,
      total_tax_8: 0,
      total_tax_10: 0,
      service_charge: 0,
      great_grand_total: 0,
      amount_after_discount: 0,
      coupon_code_applied: coupon_code_applied,
      coupon_discount: orderDetail?.coupon_discount,
      coupon_type: orderDetail?.coupon_type , 
      coupon_code_amount : orderDetail.coupon_code_amount
    };
    const subOrders = orderDetail?.subOrders;
    // console.log(orderDetail , "check")
    if (orderDetail) {
      data.subtotal += orderDetail?.subtotal;
      data.tax_price += orderDetail?.tax_price;
      // data.total_tax_8 += orderDetail?.total_tax_8;
      // data.total_tax_10 += orderDetail?.total_tax_10;
      orderDetail.ordered_items.forEach((item) => {
        if (item.consumption_tax_rate === 8) {
          data.total_tax_8 += ((item?.tax_price + item?.price) * item.quantity);
        } else if (item.consumption_tax_rate === 10) {
          data.total_tax_10 += ((item?.tax_price + item?.price) * item.quantity);
        }
      })
      data.grand_total += orderDetail?.grand_total;
      data.service_charge += orderDetail?.service_charge;
    }
    if (subOrders && subOrders.length) {
      subOrders.forEach((order) => {
        if (order) {
          console.log(order)
          data.subtotal += order?.subtotal;
          data.tax_price += order?.tax_price;
          // data.total_tax_8 += order?.total_tax_8;
          // data.total_tax_10 += order?.total_tax_10;
          data.grand_total += order?.grand_total;
          data.service_charge += order?.service_charge;

          

          order.ordered_items.forEach((item) => {
            if (item.consumption_tax_rate === 8) {
              data.total_tax_8 += ((item?.tax_price + item?.price) * item.quantity);
            } else if (item.consumption_tax_rate === 10) {
              data.total_tax_10 += ((item?.tax_price + item?.price) * item.quantity);
            }
          })


        }
      });
    }
      data.great_grand_total = data.subtotal + data.service_charge + data.tax_price;
      data.amount_after_discount = data.great_grand_total;
    if (orderDetail?.gift_card_applied) {
      data.amount_after_discount = data?.amount_after_discount - orderDetail?.gift_card_amount

    }

    if (orderDetail?.coupon_code_applied) {
      data.amount_after_discount = data?.amount_after_discount - orderDetail?.coupon_code_amount;
    }

    setOrderPriceDetail(data);
  };

  useEffect(() => {
    if (params.tableId) {
      getTableDetail(params.tableId);
    }
  }, [params.tableId]);

  const handleKeypadClick = (value) => {
    if (value === 'C') {
      setAmountReceived(0);
    } else {
      setAmountReceived((prev) => { if (prev == 0) { return value } else { return prev + value } });
    }
  };
  const keyboardHandleMouseDown = (event) => {
    event?.target?.classList?.add('active');
  };
  const keyboardHandleMouseUp = (event) => {
    event?.target?.classList?.remove('active');
  };


  useEffect(() => {
    PaymentMethodList({ global_search: PAYMENT_METHOD_TYPES.CREDIT_CARD }).then((response) => {
      setCreditCardPaymentMethod(response.data.list);
    }).catch((err) => {
      console.error(err)
    })
    PaymentMethodList({ global_search: PAYMENT_METHOD_TYPES.QR_PAYMENTS }).then((response) => {
      setOtherPaymentMethod(response.data.list);
    }).catch((err) => {
      console.error(err)
    })
  }, []);

  useEffect(() => {
    const element = amountReceivedRef.current;
    if (element) {
      const adjustFontSize = () => {
        element.classList.remove(
          'font-size-30px',
          'font-size-25px',
          'font-size-20px',
          'font-size-15px',
          'font-size-12px'
        );
        if (element.scrollWidth > 200) {
          element.classList.add('font-size-12px');
        } else if (element.scrollWidth > 150) {
          element.classList.add('font-size-15px');
        } else if (element.scrollWidth > 100) {
          element.classList.add('font-size-20px');
        } else if (element.scrollWidth > 50) {
          element.classList.add('font-size-25px');
        } else {
          element.classList.add('font-size-30px');
        }
      };

      adjustFontSize();
      window.addEventListener('resize', adjustFontSize);
      return () => {
        window.removeEventListener('resize', adjustFontSize);
      };
    }
  }, [amountReceived]);


  const handlePayment = () => {
    if ((!giftCardApplied && !couponCodeApplied && amountReceived < orderPriceDetail.great_grand_total) || (giftCardApplied && couponCodeApplied && amountReceived < orderPriceDetail.amount_after_discount)) {
      Swal.fire({
        icon: "error",
        text: DT(t("msg_amount_not_equal_total"), [showPrice(amountReceived), ((!giftCardApplied & !couponCodeApplied) ? showPrice(orderPriceDetail.great_grand_total) : showPrice(orderPriceDetail.amount_after_discount))]),
        ...SWAL_SETTINGS,
      });
      return;
    }
    else {
      setLoader(true);
      let obj = {
        subtotal: orderPriceDetail?.subtotal,
        tax_price: orderPriceDetail?.tax_price,
        total_tax_8: orderPriceDetail?.total_tax_8,
        total_tax_10: orderPriceDetail?.total_tax_10,
        grand_total: orderPriceDetail?.grand_total,
        great_grand_total: orderPriceDetail?.great_grand_total,
        service_charge: orderPriceDetail?.service_charge,
        received_amount: amountReceived,
        table_id: params?.tableId,
        cashier_id: cashierData?._id,
        order_id: tableDetail?.activeOrder?._id,
        giftCardApplied: giftCardApplied,
        couponCodeApplied: couponCodeApplied,
        amount_after_discount: giftCardApplied || couponCodeApplied ? orderPriceDetail?.amount_after_discount : orderPriceDetail?.great_grand_total,
        gift_card_amount: orderPriceDetail?.gift_card_amount,
        coupon_code_amount: orderPriceDetail?.coupon_code_amount,
        coupon_code:tableDetail?.activeOrder?.coupon_code,
      }
      payByCash(obj)
        .then((response) => {
          if (response.success) {
            setTimeout(() => {
              setLoader(false);
              navigate(`/cashier/payment-success/${tableDetail?.activeOrder?._id}`);
            }, LOADER_TIMEOUT_TIME);
          } else {

            setTimeout(() => {
              setLoader(false);
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }, LOADER_TIMEOUT_TIME);

          }
        })
        .catch((error) => {
          setLoader(false);
          console.error("error--->", error)
        });
    }
  };
  
  /** Attaching the hardware keyboard with virtual keyboard */
  useEffect(()=>{
    function handleKeyEvents(event){
      if(window.location.pathname?.includes("/cashier/payment-via-cash")){
        const allNumbers = ["0","1","2","3","4","5","6","7","8","9"];
        if(allNumbers.indexOf(event.key)>=0){
          const keyboardElement = document.getElementById(`keyboard-key-${event.key}`);
          if(keyboardElement){
            if(event.type === "keyup"){
              keyboardElement.click();
              keyboardElement.classList.remove("active");
            }else{
              keyboardElement.classList.add("active");
            }
          }
        }else if(event.key === "Backspace" || event.key === "Delete"){
          const keyboardElement = document.getElementById(`keyboard-key-C`);
          if(keyboardElement){
            if(event.type === "keyup"){
              keyboardElement.click();
              keyboardElement.classList.remove("active");
            }else{
              keyboardElement.classList.add("active");
            }
          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyEvents);
    window.addEventListener('keyup', handleKeyEvents);
    return () => {
      window.removeEventListener('keydown', handleKeyEvents);
      window.removeEventListener('keyup', handleKeyEvents);
    };
  },[]);


  return (
    <div className='dashboard-cashier-wrap'>
      {loader && (
        <div className='loader-box'>
          <div className='loader-in'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#FFFFFF"></stop><stop offset=".3" stop-color="#FFFFFF" stop-opacity=".9"></stop><stop offset=".6" stop-color="#FFFFFF" stop-opacity=".6"></stop><stop offset=".8" stop-color="#FFFFFF" stop-opacity=".3"></stop><stop offset="1" stop-color="#FFFFFF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FFFFFF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
            <span>{t('label_waiting_payment')} </span>
          </div>
        </div>)}
      <div className="cashier-head text-center">
        <h4>{t('payment_label')}</h4>
        <div className="btns-head-in-left" onClick={() => navigate('/cashier/dashboard')}>
          <button className="btn btn-icon"><i className="ti ti-arrow-left"></i></button>
        </div>
      </div>
      <div className='payment-wraper'>
        <div className='payment-wraper-in'>
          <div className='paymnet-typing shadow-box'>
            <span>{t('label_total')}</span>
            <h4>¥ {(giftCardApplied || couponCodeApplied) ? showPrice(orderPriceDetail?.amount_after_discount) : showPrice(orderPriceDetail?.great_grand_total)}</h4>
          </div>
          <div className='payment-keypad-box shadow-box'>
            <div className='amount-received-box d-flex align-items-center justify-content-between'>
              <h4>{t('label_amt_recieved')}</h4>
              <h5 ref={amountReceivedRef}>¥ {amountReceived}</h5>
            </div>
            <div className='payment-keypad'>
              <div className='row'>
                {keypadButtons.map((button, index) => (
                  <div className='col-md-4' key={index}>
                    <button disabled={String(amountReceived).length >= 13 && button !== "C"} className='cashier-key-button' id={`keyboard-key-${button}`} onClick={() => handleKeypadClick(button)} onMouseDown={keyboardHandleMouseDown} onMouseUp={keyboardHandleMouseUp}>{button}</button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='keypad-action-btn-left gap-3'>
            <button className='keypad-action-btns' data-bs-toggle="modal" data-bs-target="#pay-with-other">
              <img src={card1} />
              <span>{t('label_pay_via_other')}</span>
            </button>
            <button className='keypad-action-btns mt-5' data-bs-toggle="modal" data-bs-target="#pay-card-modal">
              <img src={card2} />
              <span>{t('label_pay_via_card')}</span>
            </button>
          </div>
          <div className='keypad-action-btn-right gap-3'>
            <button className='keypad-action-btns' onClick={handlePayment}>
              <img src={PayIcon} />
              <span>{t('label_pay_via_cash')}</span>
            </button>
          </div>

          <div className="modal fade modal-cashier" id="pay-card-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                  <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                    <i className='ti ti-arrow-left'></i>
                  </button>
                  <h1 className="modal-title fs-5" id="exampleModalLabel">{t("label_choose_credit_card")}</h1>
                  <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                    <i className='ti ti-x'></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className='payment-radio'>
                    {
                      creditCardPaymentMethod.length ?
                        <div className='row'>
                          {creditCardPaymentMethod.map((item, index) => (
                            <div className='col-md-3' key={index}>
                              <div className='payment-radio-card'>
                                <input type='radio' id={item.payment_type} name={item.payment_type} />
                                <img src={visa} alt="card" />
                                <span>{item.payment_type}</span>
                              </div>
                            </div>
                          ))}

                        </div>
                        :
                        <RecordNotFound heading='no_staff_found' />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade modal-cashier" id="pay-with-other" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                  <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                    <i className='ti ti-arrow-left'></i>
                  </button>
                  <h1 className="modal-title fs-5" id="exampleModalLabel">{t("label_choose_payment_method")}</h1>
                  <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                    <i className='ti ti-x'></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className='payment-radio payment-method-radio'>
                    <div className='payment-radio'>
                      {
                        otherPaymentMethod.length ?
                          <div className='row'>
                            {otherPaymentMethod.map((item, index) => (
                              <div className='col-md-3' key={index}>
                                <div className='payment-radio-card'>
                                  <input type='radio' id={item.payment_type} name={item.payment_type} />
                                  <img src={linepay} alt="card" />
                                  <span>{item.payment_type}</span>
                                </div>
                              </div>
                            ))}

                          </div>
                          :
                          <RecordNotFound heading='no_staff_found' />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
