import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { todaySales, transactionHistory } from '../../../services/sales.service';
import { useTranslation } from 'react-i18next';
import { LOADER_TIMEOUT_TIME } from '../../../../../utils/Constants';
import { capitalizeFirstLetter, showPrice } from '../../../../../utils/commonfunction';

export default function Sale() {

  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [List, setList] = useState([]);
  const [saleData, setSaleData] = useState(null);
  const [transactionData, setTransactionData] = useState(null);

  const getTodaySales = () => {
    setLoader(true);
    todaySales().then(response => {
      setSaleData(response.data)
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }


  const getTransactionHistory = () => {
    setLoader(true);
    transactionHistory().then(response => {
      setTransactionData(response.data)
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }
  useEffect(() => {
    getTodaySales();
    getTransactionHistory();
  }, []);

  const generateTimeLabels = () => {
    const categories = [];

    for (let i = 0; i < 24; i++) {
      const date = new Date();

      date.setHours(i, 0, 0, 0);

      const timeString = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });

      categories.push(timeString);
    }

    return categories;
  };

  const chartId = 'my-area-chart';
  const [options] = useState({
    chart: {
      id: chartId,
      height: 280,
      type: 'area',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#f28c40'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        gradientToColors: ['#f28c40'],
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 100, 90],
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    colors: ['#eb8c34'],
    xaxis: {
      categories: generateTimeLabels(),
      labels: {
        style: {
          colors: '#000',
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      opposite: false, 
      labels: {
        formatter: (value) => `¥${value.toFixed(2)}` // Ensure two decimal places
      }
    },
    legend: {
      position: 'top'
    },
    grid: {
      show: true,
      borderColor: '#EEE',
      strokeDashArray: 2,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    markers: {
      size: 3,
      colors: ['#fff'],
      strokeColors: ['#f28c40'],
      strokeWidth: 1,
      fillOpacity: 1,
      hover: {
        size: undefined,
        sizeOffset: 0
      }
    },
  });

  const seriesData = [
    {
      name: t('nav_link_sales'),
      data: transactionData && transactionData.length ? transactionData.map((item) => item.totalSales) : []
    }
  ];

  console.log("seriesData", seriesData)
  return (
    <>
      <div className='cashier-heading-innner mb-4'>
        <h4>Today’s Sales</h4>
      </div>
      <div className='card'>
        <div className='card-body'>
          <ReactApexChart options={options} series={seriesData} type="area" height={350} />
        </div>
      </div>

      <div className='card mt-4'>
        <div className='card-body'>
          <div className='today-statics-info'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='today-statics-box'>
                  <h4 className='today-static-headings'>{t('label_sales')}</h4>
                  <div className='today-static-row'>
                    <span>{t('label_sales')}</span>
                    {/* <h5>¥ {showPrice(saleData?.totalSales-(saleData?.settledOrders?.totalDiscount||0+saleData?.unsettledOrders?.totalDiscount||0)|| 0)}</h5>
                    <p>¥ {showPrice(saleData?.totalSalesExcludingTax-(saleData?.settledOrders?.totalDiscount||0+saleData?.unsettledOrders?.totalDiscount||0) || 0)} ({capitalizeFirstLetter(t('excluding_tax'))})</p> */}
                     <h5>¥ {showPrice(saleData?.totalSales|| 0,3)}</h5>
                     <p>¥ {showPrice(saleData?.totalSalesExcludingTax || 0,3)} ({capitalizeFirstLetter(t('excluding_tax'))})</p>
                  </div>
                  <div className='today-static-row'>
                    <span>{t('settled')}</span>
                    {/* <h5>¥ {showPrice(saleData?.settledOrders?.totalAmount-saleData?.settledOrders?.totalDiscount || 0)}</h5>
                    <p>¥ {showPrice(saleData?.settledOrders?.totalAmountExcludingTax-saleData?.settledOrders?.totalDiscount || 0)} ({capitalizeFirstLetter(t('excluding_tax'))})</p> */}
                    <h5>¥ {showPrice(saleData?.settledOrders?.totalAmount|| 0,3)}</h5>
                    <p>¥ {showPrice(saleData?.settledOrders?.totalAmountExcludingTax || 0,3)} ({capitalizeFirstLetter(t('excluding_tax'))})</p>
                  </div>
                  <div className='today-static-row'>
                    <span>{t('label_not_yet_settled')}</span>
                    {/* <h5>¥  {showPrice(saleData?.unsettledOrders?.totalAmount- saleData?.unsettledOrders?.totalDiscount || 0)}</h5>
                    <p>¥  {showPrice(saleData?.unsettledOrders?.totalAmountExcludingTax- saleData?.unsettledOrders?.totalDiscount || 0)} ({capitalizeFirstLetter(t('excluding_tax'))})</p> */}
                    <h5>¥  {showPrice(saleData?.unsettledOrders?.totalAmount || 0,3)}</h5>
                    <p>¥  {showPrice(saleData?.unsettledOrders?.totalAmountExcludingTax || 0,3)} ({capitalizeFirstLetter(t('excluding_tax'))})</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='today-statics-box'>
                  <h4 className='today-static-headings'>{t('number_of_customers')}</h4>
                  <div className='today-static-row'>
                    <span>{t("label_persons")}</span>
                    <h5>{saleData?.totalCustomers || 0}</h5>
                  </div>
                  <div className='today-static-row'>
                    <span>{t('settled')}</span>
                    <h5>{saleData?.settledCustomers || 0}</h5>
                  </div>
                  <div className='today-static-row'>
                    <span>{t('label_not_yet_settled')}</span>
                    <h5>{saleData?.unsettledCustomers || 0}</h5>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='today-statics-box'>
                  <h4 className='today-static-headings'>{t('number_of_transactions')}</h4>
                  <div className='today-static-row'>
                    <span>{t('title_transactions')}</span>
                    <h5>{saleData?.totalTransactions || 0}</h5>
                  </div>
                  <div className='today-static-row'>
                    <span>{t('settled')}</span>
                    <h5>{saleData?.totalSettledTransactions || 0}</h5>
                  </div>
                  <div className='today-static-row'>
                    <span>{t('label_not_yet_settled')}</span>
                    <h5>{saleData?.totalUnsettledTransactions || 0}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
