import React, { useEffect, useMemo, useState } from 'react';
import logo from '../../../assets/website/images/logo.svg';
import english from '../../../assets/website/images/english.png';
import japanese from '../../../assets/website/images/japanese.png';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { getLocalKey, setLocalKey } from '../../../utils/commonfunction';
import { Link, NavLink} from 'react-router-dom';
import { CMSOptions } from '../services/website.services';
import { MENU_LOCATION } from '../../../utils/Constants';

function WebsiteHeader() {
  const { t } = useTranslation();
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [refreshOptionsAPI, setRefreshOptionsAPI] = useState(false);
  const [flag, setFlag] = useState(getLocalKey("system_language_flag") || english);

  const changeLanguage = (lang, flag) => {
    i18next.changeLanguage(lang);
    setLocalKey("system_language", lang);
    setLocalKey("system_language_flag", flag);
    setFlag(flag);
    const evt = new CustomEvent("language_change");
    document.dispatchEvent(evt);
  };

  useEffect(() => {
    CMSOptions(MENU_LOCATION.menu_of_page_header).then((response) => {
      setDynamicOptions(response?.data);
    }).catch((error) => {
      console.log("error of terms&conditions",error);
    });
  },[refreshOptionsAPI]);

  useMemo(()=>{
    document.addEventListener('language_change',function(){
      setRefreshOptionsAPI(!refreshOptionsAPI);
    })
  });

  return (
    <div className='header-marketing'>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link className="navbar-brand" to={'/'}><img src={logo} alt=''/></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to={'/'}>{t("web_nav_link_home")}</NavLink>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#about_us">{t("web_nav_link_about_us")}</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#service_overview">{t("web_nav_link_service_overview")}</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/#how_it_works">{t("web_nav_link_how_does_it_operate")}</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="#">{t("web_nav_link_pricing")}</Link>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" to={'/manual-guide'}>{t("web_nav_link_manual_guide")}</NavLink>
              </li>
              {
                dynamicOptions?.map((item,i)=>{
                  return (
                    <li className="nav-item" key={i}>
                      <NavLink className="nav-link" to={`/org-info/${item?.slug}`}>{item?.title}</NavLink>
                    </li>
                  )
                })
              }
              
            </ul>
            <div className='header-w-right d-flex align-items-center ms-auto gap-3'>
              <div className="dropdown profile-dropdown lang-dropdown ">
                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className='avatar avatar-xxs'>
                    <img src={flag} alt='english-flag' />
                  </div>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item cp" onClick={(e) => { e.preventDefault(); changeLanguage("en", english) }}>
                      <figure className='avatar avatar-xxs'>
                        <img src={english} alt='Thai-flag' />
                      </figure>
                      <span>
                        English
                      </span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item cp" onClick={(e) => { e.preventDefault(); changeLanguage("ja", japanese) }}>
                      <figure className='avatar avatar-xxs'>
                        <img src={japanese} alt='english-flag' />
                      </figure>
                      <span>
                        Japanese
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <Link className="btn btn-primary" to="/#inquiry">{t("web_nav_link_inquire_now")}</Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default WebsiteHeader
