import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DatePicker, DateRangePicker } from "rsuite";
import { LOADER_TIMEOUT_TIME, SORT_A, SORT_D } from "../../../../../utils/Constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { salesSlipChangesList } from "../../../services/sales-slip.service";
import { capitalizeFirstLetter, capitalizeFirstLetterOfEachWord, formatDateAndTime, formateDate, formatTime, getDateInFormat, globalLoader, showPrice, TrimText, writeMyExcel } from "../../../../../utils/commonfunction";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Sorting from "../../../shared/sorting";
import Skeleton from "react-loading-skeleton";
import RecordNotFound from "../../../shared/recordNotFound";
import CustomPagination from "../../../../admin/common/custompagination";

export default function SalesSlipChanges() {
  const { t } = useTranslation();
  const params = useParams();
  const [List, setList] = useState([]);
  const today = new Date();
  const initialSelectedDate = today.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const lowerButtonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [todayDate, setTodayDate] = useState(new Date());
  const closeModalRef = useRef();
  const componentRef = useRef();
  const [sortConfig, setSortConfig] = useState({ column: "createdAt", order: SORT_D });
  const [Loader, setLoader] = useState(true);
  const [modelData, setModelData] = useState({});
  const [page, setPage] = useState(1);
  const [offset, setOffsetPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemPerPage] = useState(10);

  console.log(modelData);

  const handleUpperButtonClick = () => {
    if (lowerButtonRef.current) {
      if (isDatePickerOpen) {
        // Close the DatePicker
        lowerButtonRef.current.close(); // Close the DatePicker programmatically
      } else {
        // Open the DatePicker
        lowerButtonRef.current.open(); // Open the DatePicker programmatically
      }
      setIsDatePickerOpen(!isDatePickerOpen); // Toggle the open/close state
    }
  };

  const handleDateChange = (value) => {
    setTodayDate(value);
    //   const formattedDate =value.toLocaleDateString('en-GB', {
    //     day: '2-digit',
    //           month: 'short',
    //           year: 'numeric',
    //         });
    // console.log(value);
    setSelectedDate(value); // Update the displayed value
    setIsDatePickerOpen(false); // Close the DatePicker after selection
  };
  const handleToggle = (open) => {
    setIsDatePickerOpen(open);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  useEffect(() => {
    let data = { created: selectedDate };
    if (sortConfig) {
      data.sort = JSON.stringify(sortConfig);
    }
 
    setLoader(true);
  
    let page = params.page;
    data.page = page;
    data.per_page = itemPerPage;

  
    salesSlipChangesList(data)
      .then((response) => {
        //   console.log(response.data);
        setList(response.data.list ? response.data.list : []);
        setOffsetPage(response?.data?.offset);
        setTotalRecords(response?.data?.total_records || 0);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        console.log(err);
      });
  }, [selectedDate, sortConfig, params.page]);

  // const handleDownload = () => {
  //   globalLoader(true);
  //   downloadPdf();
  // };

  // const downloadPdf = useReactToPrint({
  //   onPrintError: (error) => console.log(error),
  //   content: () => componentRef.current,
  //   removeAfterPrint: true,
  //   print: async (printIframe) => {
  //     const document = printIframe.contentDocument;
  //     if (document) {
  //       const html = document.getElementsByTagName("html")[0];
  //       const canvas = await html2canvas(html, { scale: 2 });
  //       const pdf = new jsPDF();
  //       const imgData = canvas.toDataURL("image/png");
  //       const imgWidth = 210;
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //       let yOffset = 10;
  //       let pageHeight = 297;

  //       if (imgHeight > pageHeight) {
  //         pdf.addImage(imgData, "PNG", 0, yOffset, imgWidth, imgHeight);
  //         pdf.addPage();
  //         yOffset = 0;
  //       } else {
  //         pdf.addImage(imgData, "PNG", 0, yOffset, imgWidth, imgHeight);
  //       }
  //       pdf.save("transaction_history.pdf");
  //       globalLoader(false);
  //     }
  //   },
  // });

  const handleExportToExcel = () => {
    // Prepare the data for export
    const headers = [
      t("label_date_time"),
      t("label_slip_number"),
      t("label_table_movement"),
      t('label_menu_item_name'), 
      t('label_quantity_before'), 
      t('label_quantity_after'), 
      t('label_amount_before'), 
      t('label_amount_after'), 
      t("label_manager_name"),
      t("label_type"),
      t("label_men"),
      t("label_women"),
      t("label_all"),
      t("label_payment_method"),
   
   
    ];

    const rows = List.map((item) => [
      String(formateDate(item?.createdAt, "DD/MM/YYYY", true, false)), // Format the date
      String(item?.transaction_detail?.slip_number)?.padStart(4, "0") || "", // Slip number
      String(`${item?.previous_table_detail?.table_number || ""}→${item?.updated_table_detail?.table_number || ""}`), // Combined table numbers
      String(item?.beforeChange?.changed_menu_item?.item_name || "-"), // Trimmed item name
      String(item?.beforeChange?.changed_menu_item?.quantity>=0 ? item?.beforeChange?.changed_menu_item?.quantity  : "-"), // Before change quantity
      String(item?.afterChange?.changed_menu_item?.quantity>=0 ? item?.afterChange?.changed_menu_item?.quantity  :"-"), // After change quantity
      String(item?.beforeChange?.changed_menu_item?.price ? `¥${item.beforeChange.changed_menu_item.price}` : "-"), // Before change price
      String(item?.afterChange?.changed_menu_item?.price ? `¥${item.afterChange.changed_menu_item.price}` : "-"), // After change price      
      String(capitalizeFirstLetterOfEachWord(item?.updated_cashier_detail?.name || "")), // Cashier name
      String(`${item?.afterChange?.type === "restaurant_order" ? t("label_sales") : capitalizeFirstLetter(item?.afterChange?.type || "")}
      →
      ${item?.beforeChange?.type === "restaurant_order" ? t("label_sales") : capitalizeFirstLetter(item?.beforeChange?.type || "")}
      `), // Order type
      String(`${item?.beforeChange?.men || 0}→${item?.afterChange?.men || 0}`), // Men count before and after change
      String(`${item?.beforeChange?.women || 0}→${item?.afterChange?.women || 0}`), // Women count before and after change
      String(`${item?.beforeChange?.number_of_persons || 0}→${item?.afterChange?.number_of_persons || 0}`),
      String(`${capitalizeFirstLetterOfEachWord(item?.beforeChange?.payment_method || "")}→${capitalizeFirstLetterOfEachWord(item?.afterChange?.payment_method || "")}`), // Payment method before and after
    ]);
    



    writeMyExcel([headers, ...rows], "sales_slip_change_report.xlsx");
  };

  const handleSort = (key) => {
    let direction = SORT_A;
    if (sortConfig.column === key && sortConfig.order === SORT_A) {
      direction = SORT_D;
    }
    setSortConfig({ column: key, order: direction });
  };

  return (
    <main id="main-content">
      <div className="menu-level-3">
        <ul className="nav nav-pills gap-3">
          <li className="nav-item">
            <Link className="nav-link " to={`/restaurant/sales/sales-transaction-history/${1}`}>
              {t("label_sales_slip")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link active" to={`/restaurant/sales/sales-slip-changes/${1}`}>
              {t("label_list_of_changes")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="inner-container">
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
          <div style={{ position: "relative", display: "inline-block" }}>
            {/* Upper Button */}
            <button className="btn btn-date" onClick={handleUpperButtonClick}>
              <i className="ti ti-calendar" style={{ color: "#757575" }}></i>{" "}
              <span style={{ color: "#757575" }}>
                {todayDate.length > 0
                  ? `${new Date(selectedDate[0]).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })} - ${new Date(selectedDate[1]).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}`
                  : t("placeholder_date1")}
              </span>{" "}
              <i className={`ti ${isDatePickerOpen ? "ti-chevron-up" : "ti-chevron-down"}`} style={{ color: "#757575" }}></i>
            </button>

            {/* Hidden DatePicker */}
            <div
              style={{
                visibility: "hidden",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <DateRangePicker
                ref={lowerButtonRef}
                classPrefix="restaurant"
                placement="bottomStart"
                container={() => document.body}
                format={"dd/MM/yyyy"}
                cleanable={false}
                editable={false}
                value={todayDate}
                onChange={handleDateChange}
                onToggle={handleToggle} // Handle open/close state
                disabledDate={(date) => date > new Date()}
                onClose={handleDatePickerClose}
              />
            </div>
          </div>

          <div className="card-head-group d-flex align-items-center gap-2">
            <button
              className="btn btn-black"
              onClick={() => {
                List?.length && handleExportToExcel();
              }}
              disabled={!List?.length}
            >
              <i className="ti ti-download"></i> {t("label_download")}
            </button>
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-black" disabled={!List?.length}>
                  <i className="ti ti-printer"></i> {t("label_print")}
                </button>
              )}
              content={() => componentRef.current}
              pageStyle={`
              @media print {
                @page {
                  size: landscape; /* Print in landscape mode */
                  margin: 1cm; /* Adjust margins for print */
                }
                body {
                  font-size: 12px; /* General font size for the body */
                  overflow: hidden; /* Hide any scrollbars */
                }
                .table-responsive {
                  width: 100%; /* Full width */
                  overflow: visible !important; /* Remove overflow for print */
                }
                table {
                  width: 100%; /* Ensure table takes full width */
                  border-collapse: collapse; /* Remove gaps between borders */
                  font-size: 10px; /* Reduce table font size */
                }
                th, td {
                  border: 1px solid #000; /* Add borders for clarity */
                  padding: 4px; /* Adjust padding */
                  word-wrap: break-word; /* Wrap long text */
                  text-align: left; /* Align text to the left */
                }
                th {
                  background-color: #f8f9fa; /* Light background for headers */
                  white-space: normal; /* Allow wrapping in th */
                }
                th.text-nowrap {
                  white-space: normal !important; /* Force wrapping in th */
                }
                td.text-nowrap {
                  white-space: normal !important; /* Force wrapping in td */
                  word-wrap: break-word !important; /* Ensure word wrap in td */
                }
                .no-print {
                  display: none; /* Hide elements marked with no-print */
                }
              }
            `}
            

            
            />
          </div>
        </div>
        {Loader ? (
          <div className="w-100">
            <Skeleton className="mb-2" height={50} count={5} />
          </div>
        ) : List && List.length > 0 ? (
          <div className="card">
            <div className="card-body">
              <div className="table-responsive" ref={componentRef}>
                <table className="table table-printable">
                  <thead>
                    <tr>
                      <th>{t("label_date_time")}</th>

                      <th className="text-nowrap">
                        {t("label_slip_number")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("slip_number")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "slip_number" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "slip_number" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_table_movement")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("table_number_previous")}>
                          <i
                            className={`ti ti-arrow-narrow-up ${sortConfig.column === "table_number_previous" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`}
                            style={{ marginLeft: "5px" }}
                          ></i>
                          <i
                            className={`ti ti-arrow-narrow-down ${sortConfig.column === "table_number_previous" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`}
                            style={{ marginLeft: "-8px" }}
                          />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_menu_item_name")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("item_name")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "item_name" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "item_name" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_quantity_before")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("before_quantity")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "before_quantity" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "before_quantity" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_quantity_after")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("after_quantity")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "after_quantity" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "after_quantity" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                      <th className="text-nowrap">
                        {t("label_amount_before")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("amount_before")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "amount_before" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "amount_before" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                      <th className="text-nowrap">
                        {t("label_amount_after")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("amount_after")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "amount_after" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "amount_after" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {" "}
                        {t("label_manager_name")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("name")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "name" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "name" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_type")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("type")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "type" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "type" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_men")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("men")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "men" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "men" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_women")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("women")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "women" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "women" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_all")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("number_of_persons")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "number_of_persons" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "number_of_persons" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>

                      <th className="text-nowrap">
                        {t("label_payment_method")}
                        <span style={{ cursor: "pointer" }} onClick={() => handleSort("payment_method")}>
                          <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "payment_method" && sortConfig.order === SORT_D ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                          <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "payment_method" && sortConfig.order === SORT_A ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {List && List.length > 0
                      ? List.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {" "}
                                <span className="text-nowrap">
                                  {formateDate(item?.createdAt, "DD/MM/YYYY", false, false)} {formatTime(item?.createdAt)}
                                </span>
                              </td>
                              <td className="text-center">{String(item?.transaction_detail?.slip_number)?.padStart(4, "0") || ""}</td>
                              <td>
                                {capitalizeFirstLetter(item?.previous_table_detail?.table_number)}
                                <i class="ti ti-arrow-narrow-right"></i>
                                {capitalizeFirstLetter(item?.updated_table_detail?.table_number)}
                              </td>

                              <td>{TrimText(item?.beforeChange?.changed_menu_item?.item_name, 15) || "-"}</td>
                              <td>{item?.beforeChange?.changed_menu_item?.quantity>=0 ? item?.beforeChange?.changed_menu_item?.quantity  : "-"}</td>
                              <td>{item?.afterChange?.changed_menu_item?.quantity >=0 ? item?.afterChange?.changed_menu_item?.quantity  : "-"}</td>
                              <td>{item?.beforeChange?.changed_menu_item?.price ? `¥${item.beforeChange.changed_menu_item.price}` : "-"}</td>
                              <td>{item?.afterChange?.changed_menu_item?.price ? `¥${item.afterChange.changed_menu_item.price}` : "-"}</td>
                              <td>{capitalizeFirstLetterOfEachWord(item?.updated_cashier_detail?.name)}</td>
                              <td className="text-nowrap"> {item?.beforeChange?.type === "restaurant_order" ? t("label_sales") : capitalizeFirstLetter(item?.beforeChange?.type)} <i class="ti ti-arrow-narrow-right"></i> {item?.afterChange?.type === "restaurant_order" ? t("label_sales") : capitalizeFirstLetter(item?.afterChange?.type)}</td>
                              <td>
                                {item?.beforeChange?.men}
                                <i class="ti ti-arrow-narrow-right"></i>
                                {item?.afterChange?.men}
                              </td>
                              <td>
                                {item?.beforeChange?.women}
                                <i class="ti ti-arrow-narrow-right"></i>
                                {item?.afterChange?.women}
                              </td>
                              <td>
                                {item?.beforeChange?.number_of_persons && item?.afterChange?.number_of_persons ? (
                                  <>
                                    {item.beforeChange.number_of_persons}
                                    <i class="ti ti-arrow-narrow-right"></i>
                                    {item.afterChange.number_of_persons}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>

                              <td>
                                {capitalizeFirstLetterOfEachWord(item?.beforeChange?.payment_method)}
                                <i class="ti ti-arrow-narrow-right"></i>
                                {capitalizeFirstLetterOfEachWord(item?.afterChange?.payment_method)}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
              <div className="table-status-info d-flex justify-content-between align-items-center">
                <span>
                  {t("label_showing")} {offset + List.length} of {totalRecords} {t("label_entries")}
                </span>
                <nav aria-label="Page navigation example" title={page}>
                  {totalRecords && totalRecords > 0 ? (
                    <CustomPagination
                      datalength={totalRecords}
                      itemperpage={itemPerPage}
                      currentPage={page}
                      custompath="page"
                      setPage={setPage}
                      pageRoute={[{ name: "settings", path: "/restaurant/sales/sales-slip-changes" }]}
                    />
                  ) : (
                    ""
                  )}
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <RecordNotFound heading="lbl_record_not_found" />
        )}
      </div>

      {/* modal code  */}

      {/* <div className="modal fade modal-lg" data-bs-backdrop="static" id="open-modal" tabIndex="-1" aria-labelledby="sort-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                {t("label_sales_slip_change_details")}
              </h1>

              <button type="button" className="btn-close btn-icon" data-bs-dismiss="modal" aria-label="Close" ref={closeModalRef}>
                <i className="ti ti-x"></i>
              </button>
            </div>

            <div className="modal-body p-0">
              <div className="sales-slip-details-box">
                <div className="table-box w-100">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t("label_before_change")}</th>
                          <th>{t("label_after_change")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "100px" }}>{t("label_men")}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.beforeChange?.men}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.afterChange?.men}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "100px" }}>{t("label_women")}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.beforeChange?.women}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.afterChange?.women}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "100px" }}>{t("label_choose_payment_method")}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.beforeChange?.payment_method}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.afterChange?.payment_method}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "100px" }}>{t("label_type")}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.beforeChange?.type === "cancelled" ? t('label_cancelled') : t('label_sales')}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.afterChange?.type === "cancelled" ? t('label_cancelled') : t('label_sales')}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "100px" }}>{t("label_cashier_name")}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.previous_cashier_detail?.first_name + " " + modelData?.previous_cashier_detail?.last_name}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.updated_cashier_detail?.first_name + " " + modelData?.updated_cashier_detail?.last_name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "100px" }}>{t("label_table_number")}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.previous_table_detail?.table_number}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.updated_table_detail?.table_number}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "100px" }}>{t("label_comment")}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.beforeChange?.comment}</td>
                          <td style={{ width: "100px", wordBreak: "break-all" }}>{modelData?.afterChange?.comment}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </main>
  );
}
