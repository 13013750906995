import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'


export default function Sidebar() {
  // const location = useLocation();

  const location = useLocation();
  const { t } = useTranslation();
  // console.log(location);

  return (
    <div className='sidebar'>
      <div className='sidebar-menu'>
        <ul>
          <li>
            <NavLink end to={'/restaurant/sales'} className={location.pathname.endsWith('sales')||location.pathname.endsWith('month')||location.pathname.endsWith('week')? 'active' : '' }>
              <i className='ti ti-report'></i>
              <span>{t('label_sales_report')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/sales/abc-analysis'}  className={location.pathname.includes('abc-analysis')? 'active' : '' }>
              <i className='ti ti-device-analytics'></i>
              <span>{t('label_abc_analysis')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={`/restaurant/sales/sales-transaction-history/${1}`} className={location.pathname.includes('sales-transaction-history')||location.pathname.includes('sales-slip-changes')? 'active' : '' }>
              <i className='ti ti-history'></i>
              <span>{t('label_transaction_history')}</span>
            </NavLink>
          </li>
          
          
        </ul>
      </div>
    </div>
  )
}


