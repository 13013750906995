import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import ManualGuideData from "../../shared/data/manualGuide.json";

function ManualGuideDetails() {
  const { t } = useTranslation();
  const param = useParams();
  const [filteredData, setFilteredData] = useState(null);
  const location = useLocation();
  const [childModuleData, setChildModuleData] = useState(null);

  
  useEffect(()=>{
    const mainData = ManualGuideData.filter((item) => {
      return item.slug === param.slug;
    })[0];
    setFilteredData(mainData);
    if(mainData){
      const childData = mainData.tabs.filter((item)=> item.slug === param.moduleSlug)[0];
      setChildModuleData(childData);
    }
  },[location]);
  
  return (
    <>
      <div>
        <h4>{childModuleData?.title}</h4>
        <div className="img-screen-box">
          {childModuleData?.images?.map((itm, ind) => {
            return (
              <div key={ind} className="img-screen-box-in">
                <h3>{itm.title}</h3>
                <figure>
                  <img width="100%" src={itm.imageName} alt="icon" />
                </figure>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ManualGuideDetails;
