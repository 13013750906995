import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getSessionKey,
  getSubTotalQRApp,
  getTranslatedKey,
  handleServerValidations,
  pluralText,
  roundFigurePrice,
  showPrice,
  TrimText,
} from "../../../utils/commonfunction";
import { useTranslation } from "react-i18next";
import { detail, checkoutOrder } from "../services/order.service";
import Swal from "sweetalert2";
import { CONSUMPTION_TAX_RATES, DISPLAY_METHOD_TAX_OPTIONS, ITEM_TYPES, ORDER_PLATFORM, ORDER_STATUS, SERVICE_CHARGED_CONDITION, SWAL_SETTINGS } from '../../../utils/Constants';


export default function PlaceOrder() {
  const queryParams = new URLSearchParams(window.location.search);
  const base = queryParams.get("base");
  const order_id = queryParams.get("order_id");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [token, setToken] = useState(getSessionKey("qr_user_token"));
  const [orderDetail, setOrderDetail] = useState(null);
  const [orderPriceDetail, setOrderPriceDetail] = useState(null);
  const [orderItems, setOrderItems] = useState(null);
  const ChargeSettings = useSelector((state) => {
    return state.QrOrderToken.ChargeSettings;
  });
  const [numberOfPerson, setNumberOfPerson] = useState(0);
  const [customerNotes, setCustomerNotes] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    detail({ o_id: order_id }, { token: token })
      .then((response) => {
        if( response.data.order_status===ORDER_STATUS.CHECKED_OUT ){
          const mySearchParams = new URLSearchParams(window.location.search);
          mySearchParams.set("order_id", response.data?._id)
          navigate({
            pathname: "/qr-order/success",
            search: mySearchParams.toString()
          },{
            state: {
              customerDetails:response?.data.tableOrders?.customerDetails
            }
          });
        }

        if( response.data.order_status===ORDER_STATUS.BILLED ){
          const mySearchParams = new URLSearchParams(window.location.search);
          mySearchParams.delete("order_id")
          navigate({
            pathname: "/qr-order",
            search: mySearchParams.toString()
          });
        }
        setOrderDetail(response?.data);
        handelOrderCalculations(response?.data);
        const subOrders = response?.data?.subOrders;
        let allNotes = [];
        if (response?.data?.customer_note) {
          allNotes.push(response?.data?.customer_note);
        }
        if (subOrders && subOrders.length) {
          setNumberOfPerson(
            subOrders[subOrders.length - 1]?.number_of_persons ||
              response?.data?.number_of_persons
          );
          subOrders.forEach((order) => {
            if (order?.customer_note) {
              allNotes.push(order?.customer_note);
            }
          });
        } else {
          setNumberOfPerson(response?.data?.number_of_persons);
        }
        setCustomerNotes(allNotes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [order_id]);

  const handleAddMore = () => {
    const mySearchParams = new URLSearchParams(window.location.search);
    mySearchParams.set(
      "order_id",
      orderDetail?.parent_id ? orderDetail?.parent_id : order_id
    );
    navigate({
      pathname: "/qr-order",
      search: mySearchParams.toString(),
    });
  };

  const handelOrderCalculations = (orderDetail) => {
    const combinedOrderedItems = [...orderDetail.ordered_items];
    let modifiedItems = [];
    orderDetail.subOrders.forEach((subOrder) => {
      combinedOrderedItems.push(...subOrder.ordered_items);
    });

    combinedOrderedItems.forEach((item) => {
      if (item.type === "set_of_items") {
        const existingIndex = modifiedItems.findIndex(
          (record) => record._id === item._id
        );
        if (existingIndex === -1) {
          modifiedItems.push({
            ...item,
            quantity: item.quantity,
            updatedAddOns: [],
          });
        } else {
          modifiedItems[existingIndex].quantity += item.quantity;
        }
        const itemIndex =
          existingIndex === -1 ? modifiedItems?.length - 1 : existingIndex;
        if (item.addons.length > 0) {
          item.addons.forEach((addon) => {
            const addOnIndex = modifiedItems[
              itemIndex
            ]?.updatedAddOns.findIndex(
              (a) =>
                a.addon_id === addon.addon_id &&
                a.spice_level === addon.spice_level
            );
            if (addOnIndex === -1 || addOnIndex === undefined) {
              modifiedItems[itemIndex].updatedAddOns.push({
                ...addon,
                spice_level: addon.spice_level,
                count: 1,
              });
            } else {
              const existingAddon =
                modifiedItems[itemIndex]?.updatedAddOns[addOnIndex];
              existingAddon["count"] += 1;
            }
          });
        }
      } else {
        const existingIndex = modifiedItems.findIndex(
          (record) =>
            record._id === item._id && record.spice_level === item.spice_level
        );
        if (existingIndex === -1) {
          modifiedItems.push({
            ...item,
            quantity: item.quantity,
          });
        } else {
          modifiedItems[existingIndex].quantity += item.quantity;
        }
      }
    });

    // combinedOrderedItems.forEach((item) => {
    //   const existingIndex = modifiedItems.findIndex((record => record._id === item?._id && record.spice_level === item.spice_level));
    //   if (existingIndex === -1) {
    //     modifiedItems.push({
    //       ...item, quantity: 1
    //     });
    //   } else {
    //     modifiedItems[existingIndex].quantity += 1;
    //   }
    // })
    setOrderItems(modifiedItems);

    console.log("modifiedItems",modifiedItems);
    console.log("orderDetail",orderDetail)
    let data = {
      subtotal: 0,
      tax_price: 0,
      grand_total: 0,
      total_tax_8: 0,
      total_tax_10: 0,
      service_charge: 0,
      grand_cost_price:0
    };
    const subOrders = orderDetail?.subOrders;
    if (orderDetail) {
      data.subtotal += orderDetail?.subtotal;
      data.tax_price += orderDetail?.tax_price;
      data.total_tax_8 += orderDetail?.total_tax_8;
      data.total_tax_10 += orderDetail?.total_tax_10;
      data.grand_total += orderDetail?.grand_total;
      data.service_charge += orderDetail?.service_charge;
      data.grand_cost_price += orderDetail?.grand_cost_price;
    }

    console.log("data", data.total_tax_8);
    if (subOrders && subOrders.length) {
      subOrders.forEach((order) => {
        if (order) {
          data.subtotal += order?.subtotal;
          data.tax_price += order?.tax_price;
          data.total_tax_8 += order?.total_tax_8;
          data.total_tax_10 += order?.total_tax_10;
          data.grand_total += order?.grand_total;
          data.service_charge += order?.service_charge;
          data.grand_cost_price += order?.cost_price;
        }
      });
    }
    console.log("data", data);
    setOrderPriceDetail(data);
  };

  const handelCheckout = () => {
    checkoutOrder({ o_id: order_id }, { token: token })
      .then((response) => {
        if (response.success) {
          const mySearchParams = new URLSearchParams(window.location.search);
          navigate(`/qr-order/success?${mySearchParams}`);
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleAsterisk = (item) => {
    let result = null;
    if (
      item?.consumption_tax_rate === CONSUMPTION_TAX_RATES.TAKE_OUT &&
      ChargeSettings?.icon_display_eligible_for_reduced_tax_rate
    ) {
      result = <span className="fw-bold">*</span>;
    }
    return result;
  };

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <>
      <div className="qr-body-wrap">
        <div className="p-3">
          <div className="info-order-review">
            <h4>{t("label_customer_info")}</h4>
            <div className="card card-2">
              <div className="p-2">
                <table width={"100%"}>
                  <tbody>
                    {orderDetail?.customer_name ? (
                      <tr>
                        <td>{t("full_name_label")}</td>
                        <td className="text-end">
                          {TrimText(orderDetail?.customer_name,15)}
                        </td>
                      </tr>
                    ) : null}
                    {orderDetail?.customerDetails?.customer_phone_number ? (
                      <tr>
                        <td>{t("label_phone_number")}</td>
                        <td className="text-end">
                          {orderDetail?.customerDetails?.customer_phone_number}
                        </td>
                      </tr>
                    ) : null}
                    {numberOfPerson ? (
                      <tr>
                        <td>{t("number_of_person_text")}</td>
                        <td className="text-end">{numberOfPerson}</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {orderItems && orderItems.length ? (
            <div className="items-edit-delete info-order-review mt-4">
              <h4>{t("label_items")} </h4>
              <div className="d-flex gap-2 flex-column">
                {orderItems?.map((item, index) => {
                  const isOpen = openAccordion === index;
                  return (
                    <div className="card card-2" key={index}>
                      <div className="p-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="">
                            <span className="d-block">
                              {getTranslatedKey("item_name", item)}{" "}
                              {handleAsterisk(item)}{" "}
                              {item?.quantity > 1 ? (
                                <strong>{`(x${item?.quantity})`}</strong>
                              ) : null}
                              <br />
                              {item?.spice_level ? (
                                <span className="fs-12">
                                  {t("spice_level_text")} -{" "}
                                  <strong>
                                    {t("label_" + item?.spice_level)}
                                  </strong>
                                </span>
                              ) : null}
                            </span>
                            {item?.type === ITEM_TYPES.SET_OF_ITEMS ? (
                              <>
                                <span
                                  onClick={() => toggleAccordion(index)}
                                  className={`accordion-toggle ${
                                    isOpen ? "open" : ""
                                  }`}
                                >
                                  {isOpen ? (
                                    <>
                                      {t('label_see_less')}<i className="ti-angle-up order-dropdown"></i>
                                    </>
                                  ) : (
                                    <>
                                       {t('label_see_details')}
                                      <i className="ti-angle-down order-dropdown"></i>
                                    </>
                                  )}
                                </span>

                                {isOpen && (
                                  <div className="accordion-content w-100 pt-2">
                                    {item?.updatedAddOns?.map(
                                      (addon, addonIndex) => (
                                        <div
                                          className={
                                            "border p-2 rounded-2 mb-2" +
                                            (addonIndex + 1 <
                                            item?.addons?.length
                                              ? " me-1"
                                              : "")
                                          }
                                          key={addonIndex}
                                        >
                                          <span className="d-block fs-12">
                                            {getTranslatedKey(
                                              "item_name",
                                              addon
                                            )}
                                            {addon?.count > 1 ? (
                                              <strong>{`(x${addon?.count})`}</strong>
                                            ) : null}
                                          </span>
                                          {addon?.spice_level ? (
                                            <span className="d-block fs-12">
                                              {t("spice_level_text")} -{" "}
                                              <strong>
                                                {t(
                                                  "label_" + addon?.spice_level
                                                )}
                                              </strong>
                                            </span>
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </>
                            ) : null}
                          </div>
                          <div>
                            <div className="text-end">
                            ¥ {ChargeSettings?.display_method_product_price===DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ?showPrice(item?.price * item?.quantity):showPrice(item?.total_price * item?.quantity)}
                              {/* ¥ {showPrice(item?.price * item?.quantity)} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {/* {orderItems && orderItems.length ?
            <div className='info-order-review mt-4'>
              <h4>{t('label_items')}</h4>
              <div className='card card-2'>
                <div className='p-2'>
                  <table width={'100%'}>
                    <tbody>
                      {
                        orderItems.map((item, index) => {
                          return (<tr key={index}>
                            <td>{getTranslatedKey('item_name', item)} {handleAsterisk(item)} {item?.quantity > 1 ? <strong>{`(x${item?.quantity})`}</strong>:null}<br /><span className='fs-12'>{t("spice_level_text")} - <strong>{t('label_'+item?.spice_level)}</strong></span> </td>
                            <td className='text-end'>¥ {showPrice(item.price*item?.quantity)}</td>
                          </tr>)
                        })
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : ''} */}
          {customerNotes.length ? (
            <div className="info-order-review mt-4">
              <h4>{t("label_specific_requirement")}</h4>
              <div className="card card-2">
                <div className="p-2">
                  {customerNotes?.map((note, index) => {
                    return (
                      <p className="fs-14 mb-0" key={index}>
                        {customerNotes.length > 1 ? index + 1 + "." : null}{" "}
                        {note}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}

          <div className="info-order-review mt-4">
            <div className="card card-2">
              <div className="p-2">
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      <td>
                        {t("sub_total_label")}{" "}(x{orderItems?.length}
                        {/* {pluralText(
                          orderItems?.length,
                          t("label_item"),
                          t("label_items")
                        )} */}
                        )
                        {/* {ChargeSettings?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_INCLUDED && (
                        <span>
                           ({t('tax_included_label')})</span>
                      )} */}
                      </td>
                      <td className="text-end">
                        ¥&nbsp; 
                        {showPrice(orderPriceDetail?.subtotal)}
                      </td>
                    </tr>
                    {orderPriceDetail?.service_charge &&  ChargeSettings?.service_charge_applicable_condition === SERVICE_CHARGED_CONDITION.ALWAYS? (
                      <tr>
                        <td>{t("service_charge")}</td>
                        <td className="text-end">
                          ¥ {showPrice(orderPriceDetail?.service_charge)}
                        </td>
                      </tr>
                    ) : null}
                    {orderPriceDetail?.tax_price ? (
                      <tr>
                        <td>{t("total_tax_label")}</td>
                        <td className="text-end">
                          ¥ {showPrice(orderPriceDetail?.tax_price)}
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td colSpan={2} className="text-end">
                       
                          <span className="d-block no-wrap">
                            (8% {t("tax_on_text")} ¥{" "}
                            {showPrice(orderPriceDetail?.total_tax_8)})
                          </span>
                  
                       
                          <span className="d-block no-wrap">
                            (10% {t("tax_on_text")} ¥{" "}
                            {showPrice(orderPriceDetail?.total_tax_10)})
                          </span>
                          {
                            orderPriceDetail?.total_tax_8 && orderPriceDetail?.total_tax_8>0 && ChargeSettings.icon_display_eligible_for_reduced_tax_rate? <span class="d-block no-wrap">★ {t('tax_on_other_text')} <br/>{t('tax_rate_8')}</span> : ""
                          }
                         
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>{t("grand_total_text")}</h4>
                      </td>
                      <td className="text-end">
                        <h4>¥ {showPrice(orderPriceDetail?.grand_total)}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cart-bottom p-3">
        <button className="btn btn-primary w-100" onClick={handleAddMore}>
          {t("label_add_more")}
        </button>
        {
          base === ORDER_PLATFORM.WEB &&  (
<button
          className="btn btn-dark w-100 mt-2"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {t("label_checkout")}
        </button>
          )
        }
        
      </div>
      <div
        className="modal fade qr-modal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="qr-confirm-modal">
                <figure className="text-center">
                  <svg
                    width="95"
                    height="95"
                    viewBox="0 0 95 95"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="47.5"
                      cy="47.5"
                      r="47"
                      fill="#F8821B"
                      fillOpacity="0.13"
                    />
                    <g clipPath="url(#clip0_2738_3873)">
                      <path
                        d="M44.1875 74C46.0169 74 47.5 72.5169 47.5 70.6875C47.5 68.8581 46.0169 67.375 44.1875 67.375C42.3581 67.375 40.875 68.8581 40.875 70.6875C40.875 72.5169 42.3581 74 44.1875 74Z"
                        fill="#F8821B"
                      />
                      <path
                        d="M61.8545 74C63.6839 74 65.167 72.5169 65.167 70.6875C65.167 68.8581 63.6839 67.375 61.8545 67.375C60.025 67.375 58.542 68.8581 58.542 70.6875C58.542 72.5169 60.025 74 61.8545 74Z"
                        fill="#F8821B"
                      />
                      <path
                        d="M74.0003 35.3542C74.0003 43.2688 67.5608 49.7083 59.6462 49.7083C51.7315 49.7083 45.292 43.2688 45.292 35.3542C45.292 27.4395 51.7315 21 59.6462 21C67.5608 21 74.0003 27.4395 74.0003 35.3542ZM66.6201 28.7248C66.1791 28.3397 65.6033 28.1453 65.0192 28.1842C64.435 28.2231 63.8901 28.4922 63.5041 28.9323L57.3296 35.9902L54.5825 33.243C54.1684 32.8289 53.6068 32.5963 53.0212 32.5963C52.4356 32.5963 51.8739 32.8289 51.4599 33.243C51.0458 33.6571 50.8132 34.2187 50.8132 34.8043C50.8132 35.3899 51.0458 35.9515 51.4599 36.3656L55.8765 40.7823C56.0902 40.9959 56.3455 41.1634 56.6266 41.2744C56.9076 41.3855 57.2085 41.4376 57.5105 41.4277C57.8125 41.4177 58.1093 41.3459 58.3824 41.2167C58.6556 41.0874 58.8993 40.9035 59.0985 40.6763L66.8277 31.8429C67.0189 31.6246 67.1653 31.3708 67.2584 31.0959C67.3515 30.821 67.3895 30.5304 67.3703 30.2408C67.351 29.9512 67.2748 29.6682 67.1461 29.4081C67.0174 29.148 66.8386 28.9158 66.6201 28.7248Z"
                        fill="#F8821B"
                      />
                      <path
                        d="M40.8754 35.3555C40.8754 34.9823 40.9085 34.6179 40.9306 34.2513H35.0432L34.204 30.2631C33.9125 28.9068 33.1676 27.6904 32.0922 26.8141C31.0169 25.9378 29.675 25.4539 28.2879 25.4423L22.6633 25.418H22.6566C22.2174 25.4171 21.7957 25.5907 21.4845 25.9007C21.1733 26.2107 20.998 26.6316 20.9971 27.0709C20.9962 27.5102 21.1698 27.9318 21.4798 28.243C21.7898 28.5543 22.2107 28.7296 22.65 28.7305L28.2724 28.7548C29.5643 28.7592 30.695 29.6823 30.9622 30.9454L32.0774 36.2476L36.0745 55.2305H34.8025C32.0619 55.2305 29.8337 57.4587 29.8337 60.1992C29.8337 62.9398 32.0619 65.168 34.8025 65.168H67.9275C68.3667 65.168 68.788 64.9935 69.0986 64.6829C69.4092 64.3723 69.5837 63.951 69.5837 63.5117C69.5837 63.0725 69.4092 62.6512 69.0986 62.3406C68.788 62.03 68.3667 61.8555 67.9275 61.8555H34.8025C34.3632 61.8555 33.9419 61.681 33.6313 61.3704C33.3207 61.0598 33.1462 60.6385 33.1462 60.1992C33.1462 59.76 33.3207 59.3387 33.6313 59.0281C33.9419 58.7175 34.3632 58.543 34.8025 58.543H67.9275C68.3076 58.5426 68.676 58.4116 68.9711 58.172C69.2661 57.9324 69.4699 57.5987 69.5484 57.2268L71.0169 50.2551C67.7573 52.7642 63.7597 54.1252 59.6462 54.1263C49.2958 54.1263 40.8754 45.7059 40.8754 35.3555Z"
                        fill="#F8821B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2738_3873">
                        <rect
                          width="53"
                          height="53"
                          fill="white"
                          transform="translate(21 21)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </figure>
                <h4 className="text-center my-4 checkout-confirm px-5">
                  {t("confirm_checkout")}
                </h4>
                <div className="d-flex gap-3">
                  <button className="btn btn-gry w-100" data-bs-dismiss="modal">
                    {t("false_label_no")}
                  </button>
                  <button
                    className="btn btn-primary w-100"
                    onClick={handelCheckout}
                    data-bs-dismiss="modal"
                  >
                    {t("true_label_yes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
