import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { dayOfWeekSales, dayOfWeekSalesReport } from '../../../services/tableOrder.service';
import { useTranslation } from 'react-i18next';
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION, REPORT_TAX_TYPES, SORT_D, SORT_A, MONTHS, FULLMONTHS, DAYNAMES } from '../../../../../utils/Constants';
import { showPrice, getDatesOfMonth, getTotalSalesData, getMonthName, getDateWithYearFromString, globalLoader,writeMyExcel, countSpecificDayUpToToday, getCompletedMonths } from '../../../../../utils/commonfunction';
import ReactApexChart from 'react-apexcharts';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { DatePicker, SelectPicker } from 'rsuite';
import moment from 'moment';
import RecordNotFound from '../../../shared/recordNotFound';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import Skeleton from 'react-loading-skeleton';

export default function SalesReportStatusByWeek() {


  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [List, setList] = useState([]);
  const [saleData, setSaleData] = useState(null);
  const [dayByWeekSaleReport, setDayByWeekSaleReport] = useState(null);
  const [dayByWeekSaleTotalReport, setDayByWeekSaleTotalReport] = useState(null);
  const chartRef = useRef(null);
  const [reportDate, setReportDate] = useState(new Date().getFullYear());
  const [paymentMethod, setPaymentMethod] = useState('');
  const [taxType, setTaxType] = useState(REPORT_TAX_TYPES[0].value);
  const [sortConfig, setSortConfig] = useState({ column: null, order: null });
  const [seriesData, setSeriesData] = useState(null);
  const componentRef = useRef();
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false); // Toggle dropdown visibility
  

  const handleButtonClick = () => {
    setIsOpen(!isOpen); // Toggle dropdown
  };

  const handleOptionClick = (value) => {
    setReportDate(value); // Set selected value
    setIsOpen(false); // Close dropdown
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const getTodaySales = (obj) => {
    setLoader(true);
    dayOfWeekSales(obj).then(response => {
      setSaleData(response.data)

      /** Creating series data for graph */
      const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];
      const series = weekDays.map((day, index) => ({
        name: t(DAYNAMES[index]), // to get translated day name is graph,
        data: response.data.map(month => {
          const dayData = month.data.find(d => d.dayName === day);
          return dayData ? dayData.totalSales : 0;
        })
      }));
      setSeriesData(series);
      console.log("series ====> ", series);
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }


  /** For sales table information */
  const getTodaySalesReport = (obj) => {
    setLoader(true);
    dayOfWeekSalesReport(obj).then(response => {
      setDayByWeekSaleReport(response?.data?.data || []);
      setDayByWeekSaleTotalReport(response?.data?.totalValues || null)
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
    }).catch(error => {
      setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
      console.log("error ====> ", error);
    });
  }

  useEffect(() => {
    let obj = {
      date: getDateWithYearFromString(reportDate),
      paymentMethod: paymentMethod ? paymentMethod : undefined,
      taxType: taxType ? taxType : undefined
    }
    let obj2 = { ...obj }
    // if (sortConfig) {
    //   obj2 = {
    //     ...obj2,
    //     'sort': JSON.stringify(sortConfig)
    //   }
    // }
    getTodaySales(obj);
    getTodaySalesReport(obj2);
  }, [reportDate, paymentMethod, taxType]);


  const translatedMonths = FULLMONTHS.map((month, index) => t(`${month}`));

  const [options] = useState({
    chart: {
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,}
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    colors: ["#f7821b", "#268754", "#2d80fd", "#f89c09", "#bbef4d","#ad49ea","#45d8d8",],
    xaxis: {
      categories: translatedMonths
    },
    yaxis: {
      labels: {
        formatter: (value) => `¥${value?.toFixed(2)}`, // Format the y-axis labels with the Euro symbol
      },
      opposite: false,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value ? `(¥${value?.toFixed(2)})`:'0.00'; // Adds brackets around the value
        }
      },
      marker: {
        show: true,
        size: 15  
      },
    },
    legend: {
      position: 'top'
    },
    grid: {
      show: true,
      borderColor: '#EEE',
      strokeDashArray: 2,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },

    markers: {
      size: 3,
      colors: ['#fff'],
      strokeColors:  ["#f7821b", "#268754", "#2d80fd", "#f89c09", "#bbef4d","#ad49ea","#45d8d8",],
      strokeWidth: 1,
      fillOpacity: 1,
      hover: {
          size: undefined,
          sizeOffset: 0
      }
  },
  });


  const daysOfWeek = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0,
  };



  const handleSort = (column) => {
    let newOrder = "asc"; 

    if (sortConfig.column === column && sortConfig.order === "asc") {
      newOrder = "desc"; 
    }

    const sortedData = [...dayByWeekSaleReport].sort((a, b) => {
      const dayA = a.data[daysOfWeek[column]];
      const dayB = b.data[daysOfWeek[column]];

      if (newOrder === "asc") {
        return dayA.totalSales - dayB.totalSales;
      } else {
        return dayB.totalSales - dayA.totalSales;
      }
    });

    setSortConfig({ column, order: newOrder });
    setDayByWeekSaleReport(sortedData); 

    const sortedTotalData = { ...dayByWeekSaleTotalReport };
    sortedTotalData[column] = sortedData.reduce((acc, curr) => acc + curr.data[daysOfWeek[column]].totalSales, 0);
    setDayByWeekSaleTotalReport(sortedTotalData);
  };


  // Function to download the chart
  // const handleDownload = () => {
  //   const chart = chartRef.current?.chart;
  //   if (chart) {
  //     chart.dataURI().then(({ imgURI }) => {
  //       const link = document.createElement('a');
  //       link.href = imgURI;
  //       link.download = 'chart-image.png';
  //       link.click();
  //     });
  //   }
  // };


  // const handleDownloadPreview = () => {
  //   const chart = chartRef.current?.chart;
  //   if (chart) {
  //     chart.dataURI().then(({ imgURI }) => {
  //       console.log("Opening print preview");

  //       const img = new Image();
  //       img.src = imgURI;
  //       img.onload = () => {
  //         const printWindow = window.open('', '', 'height=600,width=800');
  //         printWindow.document.write('<html><head><title>Print Chart</title></head><body>');
  //         printWindow.document.write('<img src="' + img.src + '" width="100%" />');
  //         printWindow.document.write('</body></html>');
  //         printWindow.document.close();
  //         printWindow.print();
  //       };
  //     });
  //   }
  // };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2000; year--) {
    years.push({ label: year.toString(), value: year });
  }


  const handleDownload = () => {
    globalLoader(true);
    downloadPdf();
}
 
const downloadPdf = useReactToPrint({
  onPrintError: (error) => console.log(error),
  content: () => componentRef.current,
  removeAfterPrint: true,
  print: async (printIframe) => {
    const document = printIframe.contentDocument;
    if (document) {
      const html = document.getElementsByTagName("html")[0];
      const canvas = await html2canvas(html, { scale: 2 });
      const pdf = new jsPDF();
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let yOffset = 10;
      let pageHeight = 297;

      if (imgHeight > pageHeight) {
        pdf.addImage(imgData, 'PNG', 2, yOffset, imgWidth, imgHeight);
        pdf.addPage();
        yOffset = 0;
      } else {
        pdf.addImage(imgData, 'PNG', 2, yOffset, imgWidth, imgHeight);
      }
      pdf.save('daily_sales_report.pdf');
      globalLoader(false);
    }
  },
});



const handleExportToExcel = () => {
  // Prepare the data for export
  const headers = [
    t("label_month1"),
    t("label_monday"),
    t("label_tuesday"),
    t("label_wednesday"),
    t("label_thursday"),
    t("label_friday"),
    t("label_saturday"),
    t("label_sunday")
  ];

  // Helper function to reorder days from Sunday-Saturday to Monday-Sunday
  const reorderDays = (data) => {
    return [
      data[1], // Monday
      data[2], // Tuesday
      data[3], // Wednesday
      data[4], // Thursday
      data[5], // Friday
      data[6], // Saturday
      data[0]  // Sunday
    ];
  };

  // Prepare the rows based on the dayByWeekSaleReport data
  const rows = dayByWeekSaleReport.map((monthData) => {
    // Get the name of the month for the row
    const monthName = t(getMonthName(new Date(), monthData.month));

    // Reorder daily sales from Monday to Sunday and format them with "\u00a5"
    const dailySales = reorderDays(
      monthData.data.map((dayData) => `\u00a5${showPrice(dayData?.totalSales)}`)
    );

    return [monthName, ...dailySales];
  });

  if (dayByWeekSaleTotalReport) {
    // Reorder total sales from Monday to Sunday
    const totalRow = [
      t('total_label'), // Total label
      `\u00a5${showPrice(dayByWeekSaleTotalReport?.Monday)}`,
      `\u00a5${showPrice(dayByWeekSaleTotalReport?.Tuesday)}`,
      `\u00a5${showPrice(dayByWeekSaleTotalReport?.Wednesday)}`,
      `\u00a5${showPrice(dayByWeekSaleTotalReport?.Thursday)}`,
      `\u00a5${showPrice(dayByWeekSaleTotalReport?.Friday)}`,
      `\u00a5${showPrice(dayByWeekSaleTotalReport?.Saturday)}`,
      `\u00a5${showPrice(dayByWeekSaleTotalReport?.Sunday)}`
    ];

    // Push the total row to the rows array
    rows.push(totalRow);
  }

  writeMyExcel([headers, ...rows], "day_by_week_sale_report.xlsx");
};



  return (
    <main id='main-content'>
      <div className='inner-container'>
        <div className='d-flex tabs-with-etc justify-content-between align-items-center mb-4'>
          <ul className="nav nav-pills gap-3">
            <li className="nav-item">
              <Link className="nav-link " to={'/restaurant/sales'}>{t('byDay')}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={'/restaurant/sales/sales-report-status-month'}>{t('byMonth')}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to={'/restaurant/sales/sales-report-status-week'}>{t('byDayOfWeek')}</Link>
            </li>
          </ul>
          <div className='tabls-dowpdown'>
            <select className="form-control cp" name='payment_method_type' value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
              <option value="">{t('choosePaymentMethod')}</option>
              {PAYMENT_METHOD_TYPES_TRANSACTION.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
            </select>
          </div>
        </div>
        <div className='card'>
          <div className='card-header border-0'>
            <div className='d-flex justify-content-between align-items-center'>


            <div style={{ position: "relative", width: "200px" }} ref={dropdownRef}>
      {/* Button to toggle dropdown */}
      <button
        className="btn btn-date"
        onClick={handleButtonClick}
        style={{
          width: "85%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <i className="ti ti-calendar"></i>
        {reportDate}
        <i className="ti ti-chevron-down"></i>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <ul
        style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            maxHeight: "200px", // Set a fixed height
            overflowY: "auto", // Enable scrolling when content exceeds maxHeight
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#fff",
            zIndex: 1000,
            listStyle: "none",
            margin: 0,
            padding: 0,
          }}
        >
          {years.map((item, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(item.value)}
              style={{
                padding: "8px 12px",
                cursor: "pointer",
                backgroundColor: reportDate === item.value ? "#f0f0f0" : "transparent",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#f0f0f0")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
            >
              {item.value}
            </li>
          ))}
        </ul>
      )}
    </div>

              {/* <select className="form-control cp" name='reportDate' value={reportDate} onChange={(e) => setReportDate(e.target.value)} style={{ width: 200 }} >
                <option value="" disabled>{t('placeholder_year')}</option>
                {years.map((item, index) => { return (<option key={index} value={item?.value}>{item?.value}</option>) })}
              </select> */}
              <div className='card-head-group d-flex align-items-center gap-2'>
                <select className="form-control cp" name='payment_method_type' value={taxType} onChange={(e) => setTaxType(e.target.value)}>
                  {REPORT_TAX_TYPES.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
                </select>
                <button className='btn btn-black w-100' onClick={handleExportToExcel} disabled={!dayByWeekSaleReport || dayByWeekSaleReport.length===0} ><i className='ti ti-download'></i>  {t('label_download')}</button>
            
{/* <ReactToPrint trigger={() => 
                    <button className='btn btn-black w-100'><i className='ti ti-printer' ></i> {t('downloadPreview')}</button>
                  } content={() => componentRef.current} pageStyle="@media print {.no-print {display: none;}}"/> */}
                  {dayByWeekSaleReport && dayByWeekSaleReport.length ?
                                    <ReactToPrint trigger={() =>
                                        <button className='btn btn-black w-100'><i className='ti ti-printer' ></i> {t('downloadPreview')}</button>
                                    } content={() => componentRef.current} pageStyle="@media print {.no-print {display: none;}}" /> :
                                    <button className='btn btn-black w-100' disabled><i className='ti ti-printer'></i>  {t('downloadPreview')}</button>
                                }
              </div>
            </div>
          </div>
          <div className='card-body'>
            {
              seriesData ? <ReactApexChart options={options} series={seriesData} type="area" height={350} className="dayReport" /> : null}
          </div>
        </div>
        {loader ? (
          <div className="w-100">
            <Skeleton className="mb-2" height={50} count={5} />
          </div>
        ):
        <div className='card mt-4'>
          <div className='card-body'>
          {dayByWeekSaleReport && dayByWeekSaleReport.length ? 
            <div className='table-responsive' ref={componentRef}>
              <table className='table table-printable'>
                <thead>
                  <tr>
                    <th>{t("label_month1")}</th>
                    <th>{t("label_monday")}
                      <span style={{ cursor: "pointer" }} onClick={() => handleSort("Monday")}>
                        <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Monday" && sortConfig.order === "desc" ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                        <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Monday" && sortConfig.order === "asc" ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                      </span>
                    </th>
                    <th>{t("label_tuesday")}
                      <span style={{ cursor: "pointer" }} onClick={() => handleSort("Tuesday")}>
                        <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Tuesday" && sortConfig.order === "desc" ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                        <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Tuesday" && sortConfig.order === "asc" ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                      </span>
                    </th>
                    <th>{t("label_wednesday")}
                      <span style={{ cursor: "pointer" }} onClick={() => handleSort("Wednesday")}>
                        <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Wednesday" && sortConfig.order === "desc" ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                        <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Wednesday" && sortConfig.order === "asc" ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                      </span>
                    </th>
                    <th>{t("label_thursday")}
                      <span style={{ cursor: "pointer" }} onClick={() => handleSort("Thursday")}>
                        <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Thursday" && sortConfig.order === "desc" ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                        <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Thursday" && sortConfig.order === "asc" ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                      </span>
                    </th>
                    <th>{t("label_friday")}
                      <span style={{ cursor: "pointer" }} onClick={() => handleSort("Friday")}>
                        <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Friday" && sortConfig.order === "desc" ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                        <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Friday" && sortConfig.order === "asc" ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                      </span>
                    </th>
                    <th>{t("label_saturday")} <span style={{ cursor: "pointer" }} onClick={() => handleSort("Saturday")}>
                      <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Saturday" && sortConfig.order === "desc" ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                      <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Saturday" && sortConfig.order === "asc" ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                    </span></th>
                    <th>{t("label_sunday")}
                      <span style={{ cursor: "pointer" }} onClick={() => handleSort("Sunday")}>
                        <i className={`ti ti-arrow-narrow-up ${sortConfig.column === "Sunday" && sortConfig.order === "desc" ? "sort-disabled" : ""}`} style={{ marginLeft: "5px" }}></i>
                        <i className={`ti ti-arrow-narrow-down ${sortConfig.column === "Sunday" && sortConfig.order === "asc" ? "sort-disabled" : ""}`} style={{ marginLeft: "-8px" }} />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {
                    dayByWeekSaleReport && dayByWeekSaleReport.length ? dayByWeekSaleReport.map((monthData, monthIndex) => {
                      // Helper function to reorder days from Sunday-Saturday to Monday-Sunday
                      const reorderDays = (data) => {
                        return [
                          data[1], // Monday
                          data[2], // Tuesday
                          data[3], // Wednesday
                          data[4], // Thursday
                          data[5], // Friday
                          data[6], // Saturday
                          data[0]  // Sunday
                        ];
                      };

                      return (
                        <tr key={monthIndex}>
                          <td>{t(getMonthName(new Date(), monthData.month))}</td>
                          {reorderDays(monthData.data).map((dayData, dayIndex) => (
                            <td key={dayIndex}>¥{showPrice(dayData?.totalSales)}</td>
                          ))}
                        </tr>
                      )
                    }) : ""
                  }

                  {dayByWeekSaleTotalReport && (
                    <tr className='total-tr' id="totalSale">
                      <td>{t('total_label')}</td>
                      <td><span className="text-nowrap">¥ {showPrice(dayByWeekSaleTotalReport?.Monday)}</span></td>
                      <td><span className="text-nowrap">¥ {showPrice(dayByWeekSaleTotalReport?.Tuesday)}</span></td>
                      <td><span className="text-nowrap">¥ {showPrice(dayByWeekSaleTotalReport?.Wednesday)}</span></td>
                      <td><span className="text-nowrap">¥ {showPrice(dayByWeekSaleTotalReport?.Thursday)}</span></td>
                      <td><span className="text-nowrap">¥ {showPrice(dayByWeekSaleTotalReport?.Friday)}</span></td>
                      <td><span className="text-nowrap">¥ {showPrice(dayByWeekSaleTotalReport?.Saturday)}</span></td>
                      <td><span className="text-nowrap">¥ {showPrice(dayByWeekSaleTotalReport?.Sunday)}</span></td>
                    </tr>)}
                </tbody>
              </table>
            </div>: <RecordNotFound heading='lbl_record_not_found' />}
          </div>
        </div>}

        <div className='card mt-4'>
          <div className='card-header'>
            <h4 className='m-0'>{t('average_sale_by_week_text')}</h4> 
      
          </div>
          <div className='card-body' >
            <div className='row'>
              <div className='col'>
                <div className='p-2 py-3 border d-flex flex-column align-items-center justify-content-center'>
                    <span>{t("label_monday")}</span>
                    <h4 className='mt-2'>¥ {showPrice(dayByWeekSaleTotalReport?.Monday/getCompletedMonths())}</h4> 
                </div>
              </div>
              <div className='col'>
                <div className='p-2 py-3 border d-flex flex-column align-items-center justify-content-center'>
                    <span>{t("label_tuesday")}</span>
                    <h4 className='mt-2'>¥ {showPrice(dayByWeekSaleTotalReport?.Tuesday/getCompletedMonths())}</h4> 
                </div>
              </div>
              <div className='col'>
                <div className='p-2 py-3 border d-flex flex-column align-items-center justify-content-center'>
                    <span>{t("label_wednesday")}</span>
                    <h4 className='mt-2'>¥ {showPrice(dayByWeekSaleTotalReport?.Wednesday/getCompletedMonths())}</h4> 
                </div>
              </div>
              <div className='col'>
                <div className='p-2 py-3 border d-flex flex-column align-items-center justify-content-center'>
                    <span>{t("label_thursday")}</span>
                    <h4 className='mt-2'>¥ {showPrice(dayByWeekSaleTotalReport?.Thursday/getCompletedMonths())}</h4> 
                </div>
              </div>
              <div className='col'>
                <div className='p-2 py-3 border d-flex flex-column align-items-center justify-content-center'>
                    <span>{t("label_friday")}</span>
                    <h4 className='mt-2'>¥ {showPrice(dayByWeekSaleTotalReport?.Friday/getCompletedMonths())}</h4> 
                </div>
              </div>
              <div className='col'>
                <div className='p-2 py-3 border d-flex flex-column align-items-center justify-content-center'>
                    <span>{t("label_saturday")}</span>
                    <h4 className='mt-2'>¥ {showPrice(dayByWeekSaleTotalReport?.Saturday/getCompletedMonths())}</h4> 
                </div>
              </div>
              <div className='col'>
                <div className='p-2 py-3 border d-flex flex-column align-items-center justify-content-center'>
                    <span>{t("label_sunday")}</span>
                    <h4 className='mt-2'>¥ {showPrice(dayByWeekSaleTotalReport?.Sunday/getCompletedMonths())}</h4> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}