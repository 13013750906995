import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "cashier/settlement";
const options = {role: ROLE.CASHIER};


export const cashRegisterDetails = async (values) => {
  return await axiosInstance(options).get(`${path}/report`);
}

export const updateCashRegister = async (values) => {
  return await axiosInstance(options).put(`${path}/update-cash?cash_register_id=${values._id}` , values);
}
