import React, { useEffect, useRef, useState } from "react";
import RecordNotFound from "../../../../restaurant/shared/recordNotFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { cashRegisterDetails, updateCashRegister } from "../../../services/settlement.services";
import { LOADER_TIMEOUT_TIME, SWAL_SETTINGS } from "../../../../../utils/Constants";
import { useFormik } from "formik";
import { useValidationSchemas } from "../../../validator/validation";
import CustomError from "../../../../../utils/customError";
import Swal from "sweetalert2";
import { handleServerValidations, showPrice } from "../../../../../utils/commonfunction";

export default function Settlement() {
  const { t } = useTranslation();
  const [cashierData, setCashierData] = useState({});
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const cashierDetails = useSelector((state) => state?.cashierDetails?.data);
  const [isDirty, setIsDirty] = useState(true);
  const { amountValidationSchema } = useValidationSchemas();
  const closeDepositModal = useRef();
  const closeWithdrawModal = useRef();
  const [editType, setEditType] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    cashRegisterDetails()
      .then((response) => {
        // console.log(response.data);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        setCashierData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [refresh]);

  const depositForm = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema: amountValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const data = {
        amount: values.amount,
        type: editType,
        _id: cashierData.cashRegisterDetail._id,
      };
      updateCashRegister(data)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            closeDepositModal.current.click();
            resetForm();
            setRefresh(!refresh);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
          setSubmitting(false);
          resetForm();
          setRefresh(!refresh);
        })
        .catch((err) => {
          setSubmitting(false);
          resetForm();
          console.log("error ====> ", err);
          closeDepositModal.current.click();
        });
    },
  });

  const withdrawForm = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema: amountValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const data = {
        amount: values.amount,
        type: editType,
        _id: cashierData.cashRegisterDetail._id,
      };
      updateCashRegister(data)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            closeWithdrawModal.current.click();
            resetForm();
            setRefresh(!refresh);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
          setSubmitting(false);
          resetForm();
          setRefresh(!refresh);
        })
        .catch((err) => {
          setSubmitting(false);
          resetForm();
          console.log("error ====> ", err);
          closeWithdrawModal.current.click();
        });
    },
  });

  return (
    <div className="cashier-heading-innner mb-4">
      <div className="card-outer-header d-flex justify-content-between align-items-center">
        <h4 className="heading-title-text">{t("label_settlement")} </h4>
      </div>

      <div className="dashboard-content-wraper mt-4">
        <div className="category-list-wrap">
          {loader ? (
            <div>
              <Skeleton className="m-2" style={{ height: "37px" }} count={15} />
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="d-flex justify-content-between align-items-center m-1">
                    <h4 className="p-2 m-0">{t("table_title_sales")}</h4>

                    <div className="d-flex justify-content align-items-center">
                      <button
                        className="btn bg-primary m-1 text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#unique-modal-id"
                        onClick={() => {
                          setEditType("deposit");
                        }}
                        style={{
                          width: "100px",
                          height: "35px",
                          borderRadius: "4px",
                        }}
                      >
                        {t("btn_deposit")}
                      </button>
                      <button
                        className="btn bg-primary m-1 text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#withdraw-modal-id"
                        onClick={() => {
                          setEditType("withdraw");
                        }}
                        style={{
                          width: "100px",
                          height: "35px",
                          borderRadius: "4px",
                        }}
                      >
                        {t("btn_withdraw")}
                      </button>
                    </div>
                  </div>
                  <table
                    className="table table-secondary rounded"
                    style={{
                      "--bs-table-bg": "#FAFAFA",
                      borderColor: "#EEEEEE",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td className="fw-bold">{t("table_label_date")}</td>
                        <td className="text-end">{new Date().toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("table_label_operator")}</td>
                        <td className="text-end">{cashierDetails?.name || ""}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="d-flex justify-content align-items-center m-1">
                    <h4 className="p-2 m-0">{t("table_title_total_sales")} </h4>
                    <button className="btn bg-primary m-1 text-white"
                      style={{
                        width: "100px",
                        height: "35px",
                        borderRadius: "4px",
                      }}
                    >{t("btn_inspection")}</button>
                  </div>
                  <table
                    className="table table-secondary rounded"
                    style={{
                      "--bs-table-bg": "#FAFAFA",
                      borderColor: "#EEEEEE",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td className="fw-bold">{t("table_label_cash_sales")}</td>
                        <td className="text-end">¥ {showPrice(cashierData?.total_cash_payment || 0)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("table_label_credit_sales")}</td>
                        <td className="text-end">¥ {showPrice(cashierData?.total_card_payment || 0)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("table_label_other_sales")}</td>
                        <td className="text-end">¥ {showPrice(cashierData?.total_other_payment ||0 )}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4>{t("table_title_cash_breakdown")}</h4>
                  <table
                    className="table table-secondary rounded"
                    style={{
                      "--bs-table-bg": "#FAFAFA",
                      borderColor: "#EEEEEE",
                    }}
                  >
                    {/* #EEEEEE */}

                    <tbody>
                      <tr>
                        <td className="fw-bold">{t("table_label_change_breakdown")}</td>
                        <td className="text-end">¥ {showPrice(cashierData?.cashRegisterDetail?.opening_balance || 0)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("table_label_deposits")}</td>
                        <td className="text-end">¥ {showPrice(cashierData?.cashRegisterDetail?.deposit_amount || 0)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("label_withdrawal")}</td>
                        <td className="text-end">¥ {showPrice(cashierData?.cashRegisterDetail?.withdraw_amount || 0)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("table_label_other_change")}</td>
                        <td className="text-end">¥ {showPrice(cashierData?.cashRegisterDetail?.other_change || 0)} </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("label_cash_balance")}</td>
                        <td className="text-end">
                          ¥{" "}
                          {showPrice(Number(cashierData?.cashRegisterDetail?.opening_balance || 0) +
                            Number(cashierData?.cashRegisterDetail?.deposit_amount || 0) +
                            Number(cashierData?.total_cash_payment || 0) -
                            Number(cashierData?.cashRegisterDetail?.withdraw_amount || 0))}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("label_register_balance")}</td>
                        <td className="text-end"> {cashierData?.cashRegisterDetail?.settlement_status === "pending" ? "-" : `¥ ${showPrice(cashierData?.cashRegisterDetail?.closing_balance || 0)}`}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("table_label_excess_or_deficiency")}</td>
                        <td className="text-end"> {cashierData?.cashRegisterDetail?.settlement_status === "pending" ? "-" : `¥ ${showPrice(cashierData?.cashRegisterDetail?.final_balance || 0)}`} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary ">{t("btn_confirm_settlement")}</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="modal fade unique-modal-class" data-bs-backdrop="static" id="unique-modal-id" tabIndex="-1" aria-labelledby="unique-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            <div className="modal-header d-flex justify-content-between">
              <h1 className="modal-title fs-5 w-100" id="unique-modalLabel">
                Enter Deposit Amount
              </h1>
              <button
                type="button"
                className="btn btn-icon ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="unique-close-modal"
                ref={closeDepositModal}
                onClick={() => {
                  depositForm.resetForm();
                }}
              >
                <i className="ti ti-x"></i>
              </button>
            </div>

            <div className="modal-body p-2">
              <form onSubmit={depositForm.handleSubmit}>
                <div className="m-2 input-group p-2">
                <span className="input-group-text" id="basic-addon1"> ¥ </span>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    className="form-control no_spring_input pe-73px"
                    name="amount"
                    value={depositForm?.values?.amount}
                    onChange={(e) => {
                      setIsDirty(false);
                      depositForm.handleChange(e);
                    }}
                    onBlur={depositForm.handleBlur}
                  />
                  
                </div>
                <CustomError name="amount" form={depositForm} className="text-danger m-2" />

                <div className="d-flex justify-content-end m-2">
                  <button type="submit" className="btn btn-primary" style={{ width: "100px" }} disabled={isDirty}>
                    Deposit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade unique-modal-class" data-bs-backdrop="static" id="withdraw-modal-id" tabIndex="-1" aria-labelledby="withdraw-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            <div className="modal-header d-flex justify-content-between">
              <h1 className="modal-title fs-5 w-100" id="withdraw-modalLabel">
                Enter Withdrawal Amount
              </h1>
              <button
                type="button"
                className="btn btn-icon ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="withdraw-close-modal"
                ref={closeWithdrawModal}
                onClick={() => {
                  withdrawForm.resetForm();
                }}
              >
                <i className="ti ti-x"></i>
              </button>
            </div>

            <div className="modal-body p-2">
              <form onSubmit={withdrawForm.handleSubmit}>
                {console.log("withdrawForm", withdrawForm)}
                <div className="m-2 input-group p-2">
                <span className="input-group-text" id="basic-addon1"> ¥ </span>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    className="form-control no_spring_input"
                    name="amount"
                    value={withdrawForm?.values?.amount}
                    onChange={(e) => {
                      setIsDirty(false);
                      withdrawForm.handleChange(e);
                    }}
                    onBlur={withdrawForm.handleBlur}
                  />
                  
                </div>
                <CustomError name="amount" form={withdrawForm} className="text-danger m-2" />

                <div className="d-flex justify-content-end m-2">
                  <button type="submit" className="btn btn-primary" style={{ width: "100px" }} disabled={isDirty}>
                    Withdraw
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
