import React, { useEffect, useState } from "react";
import WebsiteTestimonials from "../../common/websitetestimonials";
import Inquiry from "../../common/inquiry";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import ManualGuideData from "../../shared/data/manualGuide.json";

export default function ManualGuideLayout() {
  const { t } = useTranslation();
  const param = useParams();

  const [filteredData, setFilteredData] = useState(null);
  const [childModuleData, setChildModuleData] = useState(null);
  const location = useLocation();

  
  useEffect(()=>{
    const mainData = ManualGuideData.filter((item) => {
      return item.slug === param.slug;
    })[0];
    setFilteredData(mainData);
    if(mainData){
      const childData = mainData.tabs.filter((item)=> item.slug === param.moduleSlug)[0];
      setChildModuleData(childData);
    }
  },[location]);
  
  return (
    <div className="manual-guide-page">
      <div className="manual-guide-view py-5">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div id="list-example" className="list-group">
                {filteredData?.tabs.map((item, index) => {
                  return (
                    <Link key={index} className={"list-group-item list-group-item-action" + (param.moduleSlug === item.slug ? " active":"")} to={`/manual-guide-details/${param.slug}/${item.slug}`}>
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="col-8">
              <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex="0">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      {filteredData?.breadcrumbTitle}
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {childModuleData?.breadcrumbTitle}
                    </li>
                  </ol>
                </nav>

                <div className="right-part-guide">
                  <div id="list-item-1">
                    <h4>{filteredData?.title}</h4>
                    <p>{filteredData?.description}</p>
                  </div>
                  <Outlet/>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WebsiteTestimonials id="testimonial-2" />
      <Inquiry id="inquiry-2" />
    </div>
  );
}
