import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { monthlySales, monthlySalesReport } from '../../../services/tableOrder.service';
import { useTranslation } from 'react-i18next';
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES_TRANSACTION, REPORT_TAX_TYPES, SORT_D, SORT_A, MONTHS } from '../../../../../utils/Constants';
import { showPrice, getDatesOfMonth, getTotalSalesData, getMonthName, getDateWithYearFromString, globalLoader, writeMyExcel } from '../../../../../utils/commonfunction';
import ReactApexChart from 'react-apexcharts';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { DatePicker, SelectPicker } from 'rsuite';
import moment from 'moment';
import RecordNotFound from '../../../shared/recordNotFound';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import Sorting from '../../../shared/sorting';
import html2canvas from 'html2canvas';
import Skeleton from 'react-loading-skeleton';


export default function SalesReportStatusByMonth() {

    const { t } = useTranslation();
    const [loader, setLoader] = useState(true);
    const [List, setList] = useState([]);
    const [saleData, setSaleData] = useState(null);
    const [monthlySaleReport, setMonthlySaleReport] = useState(null);
    const [monthlySaleTotalReport, setMonthlySaleTotalReport] = useState(null);
    const chartRef = useRef(null);
    const [reportDate, setReportDate] = useState(new Date().getFullYear());
    const [paymentMethod, setPaymentMethod] = useState('');
    const [taxType, setTaxType] = useState(REPORT_TAX_TYPES[0].value);
    const [sortConfig, setSortConfig] = useState({ column: "date", order: SORT_D });
    const componentRef = useRef();
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const dropdownRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false); // Toggle dropdown visibility
    
  
    const handleButtonClick = () => {
      setIsOpen(!isOpen); // Toggle dropdown
    };
  
    const handleOptionClick = (value) => {
      setReportDate(value); // Set selected value
      setIsOpen(false); // Close dropdown
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if clicking outside
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const getMonthlySales = (obj) => {
        setLoader(true);
        monthlySales(obj).then(response => {
            setSaleData(response.data);
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
        }).catch(error => {
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
            console.log("error ====> ", error);
        });
    }


    const getMonthlySalesReport = (obj) => {
        setLoader(true);
        monthlySalesReport(obj).then(response => {
          setMonthlySaleReport([...(response?.data?.monthlyData || [])].reverse());
            setMonthlySaleTotalReport(response?.data?.totalValues || null); 
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
        }).catch(error => {
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
            console.log("error ====> ", error);
        });
    }



    useEffect(() => {
        let obj = {
            date: getDateWithYearFromString(reportDate),
            paymentMethod: paymentMethod ? paymentMethod : undefined,
            taxType: taxType ? taxType : undefined
        }
        let obj2 = { ...obj }
        if (Object.keys(sorting).length > 0) {
            obj2 = {
                ...obj2,
                'sort': JSON.stringify(sorting)
            }
        }
        getMonthlySales(obj);
        getMonthlySalesReport(obj2);
    }, [reportDate, paymentMethod, taxType, sorting]);


    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 2000; year--) {
        years.push({ label: year.toString(), value: year });
    }

    const translatedMonths = MONTHS.map((month, index) => t(`${month}`));

    const chartId = 'my-area-chart';

    // Set up your chart options
    const options = {
        chart: {
            id: "chartId",
            height: 280,
            type: 'area',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#A69AFF', '#32F474'],
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                gradientToColors: ['#A69AFF'],
                inverseColors: true,
                opacityFrom: 0.7,
                opacityTo: 0.3,
                stops: [0, 100, 90],

                // opacityFrom: 0.7,
                // opacityTo: 0.9,
                // stops: [0, 90, 100],
            }
        },
        xaxis: {
            categories: translatedMonths, // Using translated months for the X-axis,
            min: 1
        },
        yaxis: {
            labels: {
                formatter: (value) => `¥ ${value?.toFixed(2)}`, // Format the y-axis labels with the Yen symbol
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return `(¥ ${value?.toFixed(2)})`; // Adds brackets around the value
                }
            },

            followCursor: true,
            intersect: false,
        },
        legend: {
            position: 'top'
        },
        grid: {
            show: true,
            borderColor: '#EEE',
            strokeDashArray: 2,
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        markers: {
            size: 3,
            colors: ['#fff'],
            strokeColors: ['#A69AFF', '#008000'],
            strokeWidth: 1,
            fillOpacity: 1,
            hover: {
                size: undefined,
                sizeOffset: 0
            }
        },
        stroke: {
            curve: 'smooth',
            width: 2
        }
    };

    // Populate the series with the data from the `saleData`
    const series = [
        {
            name: t('totalProfit'),
            data: saleData ? saleData.map((item) => item.totalProfit) : [] // Using the totalProfit for the "Profit" series
        },
        {
            name: t('nav_link_sales'),
            data: saleData ? saleData.map((item) => item.totalSales) : [] // Using the totalSales for the "Sales" series
        }
    ];


    const handleDownload = () => {
        globalLoader(true);
        downloadPdf();
    }



    const downloadPdf = useReactToPrint({
        onPrintError: (error) => console.log(error),
        content: () => componentRef.current,
        removeAfterPrint: true,
        print: async (printIframe) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName("html")[0];
                const canvas = await html2canvas(html, { scale: 2 });
                const pdf = new jsPDF();
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 210;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                let yOffset = 10;
                let pageHeight = 297;

                if (imgHeight > pageHeight) {
                    pdf.addImage(imgData, 'PNG', 2, yOffset, imgWidth, imgHeight);
                    pdf.addPage();
                    yOffset = 0;
                } else {
                    pdf.addImage(imgData, 'PNG', 2, yOffset, imgWidth, imgHeight);
                }
                pdf.save('daily_sales_report.pdf');
                globalLoader(false);
            }
        },
    });



    const handleExportToExcel = () => {
      // Prepare the data for export
      const headers = [
          t('date'),
          t('totalTransactions'),
          t('totalSales'),
          t('totalCostPrice'),
          t('totalProfit'),
          t('profitRate'),
          t('totalCash'),
          t('totalCredit'),
          t('totalOther'),
          t('totalCustomers'),
          t('men'),
          t('women'),
          t('orderPerCustomer')
      ];
  
      const rows = monthlySaleReport.map((item) => [
          t(getMonthName(item?.date)),
          item?.totalTransactions,
          `¥${showPrice(item?.totalSales)}`,
          `¥${showPrice(item?.totalCostPrice)}`,
          `¥${showPrice(item?.totalProfit)}`,
          item.profitRate ? `${item.profitRate.toFixed(2)}%` : '0%',
          `¥${showPrice(item?.totalCash)}`,
          `¥${showPrice(item?.totalCredit)}`,
          `¥${showPrice(item?.totalOther)}`,
          item?.totalCustomers,
          item?.men,
          item?.women,
          `¥${showPrice(item?.orderPerCustomer)}`
      ]);
  
      // Add total row at the end
      if (monthlySaleReport) {
          rows.push([
              t('total_label'),
              monthlySaleTotalReport?.totalTransactions,
              `¥${showPrice(monthlySaleTotalReport?.totalSales)}`,
              `¥${showPrice(monthlySaleTotalReport?.totalCostPrice)}`,
              `¥${showPrice(monthlySaleTotalReport?.totalProfit)}`,
              monthlySaleTotalReport.profitRate
                  ? `${monthlySaleTotalReport.profitRate.toFixed(2)}%`
                  : '0%',
              `¥${showPrice(monthlySaleTotalReport?.totalCash)}`,
              `¥${showPrice(monthlySaleTotalReport?.totalCredit)}`,
              `¥${showPrice(monthlySaleTotalReport?.totalOther)}`,
              monthlySaleTotalReport?.totalCustomers,
              monthlySaleTotalReport?.men || 0,
              monthlySaleTotalReport?.women || 0,
              `¥${showPrice(monthlySaleTotalReport?.orderPerCustomer)}`
          ]);
      }
  
      writeMyExcel([headers, ...rows], "monthly_sale_report.xlsx");
  };
  





    // const handleSort = (key) => {
    //     let direction = SORT_A;
    //     if (sortConfig.column === key && sortConfig.order === SORT_A) {
    //         direction = SORT_D;
    //     }
    //     setSortConfig({ column: key, order: direction });
    // };


    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (sorting.column === column && sorting.order === SORT_A) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    return (
      <main id="main-content">
        <div className="inner-container">
          <div className="d-flex tabs-with-etc justify-content-between align-items-center mb-4">
            <ul className="nav nav-pills gap-3">
              <li className="nav-item">
                <Link className="nav-link " to={"/restaurant/sales"}>
                  {t("byDay")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to={"/restaurant/sales/sales-report-status-month"}>
                  {t("byMonth")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/restaurant/sales/sales-report-status-week"}>
                  {t("byDayOfWeek")}
                </Link>
              </li>
            </ul>
            <div className="tabls-dowpdown">
              <select className="form-control cp" name="payment_method_type" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                <option value="">{t("choosePaymentMethod")}</option>
                {PAYMENT_METHOD_TYPES_TRANSACTION.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {t(item.label)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="card">
            {/* {console.log("year", years)} */}
            <div className="card-header border-0">
              <div className="d-flex justify-content-between align-items-center">

              <div style={{ position: "relative", width: "200px" }} ref={dropdownRef}>
      {/* Button to toggle dropdown */}
      <button
        className="btn btn-date"
        onClick={handleButtonClick}
        style={{
          width: "85%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <i className="ti ti-calendar"></i>
        {reportDate}
        <i className="ti ti-chevron-down"></i>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <ul
        style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            maxHeight: "200px", // Set a fixed height
            overflowY: "auto", // Enable scrolling when content exceeds maxHeight
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#fff",
            zIndex: 1000,
            listStyle: "none",
            margin: 0,
            padding: 0,
          }}
        >
          {years.map((item, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(item.value)}
              style={{
                padding: "8px 12px",
                cursor: "pointer",
                backgroundColor: reportDate === item.value ? "#f0f0f0" : "transparent",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#f0f0f0")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
            >
              {item.value}
            </li>
          ))}
        </ul>
      )}
    </div>
             
                <div className="card-head-group d-flex align-items-center gap-2">
                  <select className="form-control cp" name="payment_method_type" value={taxType} onChange={(e) => setTaxType(e.target.value)}>
                    {REPORT_TAX_TYPES.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {t(item.label)}
                        </option>
                      );
                    })}
                  </select>
                  <button className="btn btn-black w-100" onClick={handleExportToExcel} disabled={!monthlySaleReport || monthlySaleReport.length === 0}>
                    <i className="ti ti-download"></i> {t("label_download")}
                  </button>

                  {/* <ReactToPrint trigger={() =>
                                    <button className='btn btn-black w-100'><i className='ti ti-printer' ></i> {t('downloadPreview')}</button>
                                } content={() => componentRef.current} pageStyle="@media print {.no-print {display: none;}}" /> */}

                  {monthlySaleReport && monthlySaleReport.length ? (
                    <ReactToPrint
                      trigger={() => (
                        <button className="btn btn-black w-100">
                          <i className="ti ti-printer"></i> {t("downloadPreview")}
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle="@media print {.no-print {display: none;}}"
                    />
                  ) : (
                    <button className="btn btn-black w-100" disabled>
                      <i className="ti ti-printer"></i> {t("downloadPreview")}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="chart-container" style={{ position: "relative" }}>
                <ReactApexChart ref={chartRef} options={options} series={series} type="area" height={350} className="dayReport" />
              </div>
            </div>
          </div>
          {loader ? (
          <div className="w-100">
            <Skeleton className="mb-2" height={50} count={5} />
          </div>
        ):
          <div className="card mt-4">
            <div className="card-body">
              {monthlySaleReport && monthlySaleReport.length ? (
                <div className="table-responsive" ref={componentRef}>
                  <table className="table table-printable">
                    <thead>
                      <tr>
                        <th>
                         {t("label_month1")}
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="totalTransactions" columnName={t("totalTransactions")} defaultSorting={defaultSorting} breakName="true" />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="totalSales" columnName={t("totalSales")} defaultSorting={defaultSorting} breakName="true" />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="totalCostPrice" columnName={t("totalCostPrice")} defaultSorting={defaultSorting} />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="totalProfit" columnName={t("totalProfit")} defaultSorting={defaultSorting} />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="profitRate" columnName={t("profitRate")} defaultSorting={defaultSorting} />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="totalCash" columnName={t("totalCash")} defaultSorting={defaultSorting} />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="totalCredit" columnName={t("totalCredit")} defaultSorting={defaultSorting} />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="totalOther" columnName={t("totalOther")} defaultSorting={defaultSorting} />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="totalCustomers" columnName={t("totalCustomers")} defaultSorting={defaultSorting} breakName="true" />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="men" columnName={t("men")} defaultSorting={defaultSorting} />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="women" columnName={t("women")} defaultSorting={defaultSorting} />
                        </th>
                        <th>
                          <Sorting sort={sorting} handleSort={handleSort} column="orderPerCustomer" columnName={t("pricePerCustomer")} defaultSorting={defaultSorting} breakName="true" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {monthlySaleReport && monthlySaleReport.length
                        ? monthlySaleReport.map((item, index) => {
                            return (
                              <tr key={index}>
                                {/* <td>5/1 (Wed)</td> */}
                                <td className="table-date">{t(getMonthName(item?.date))}</td>
                                <td>{item?.totalTransactions}</td>
                                <td><span className="text-nowrap">¥ {showPrice(item?.totalSales)}</span></td>
                                <td><span className="text-nowrap">¥ {showPrice(item?.totalCostPrice)}</span></td>
                                <td><span className="text-nowrap">¥ {showPrice(item?.totalProfit)}</span></td>
                                <td><span className="text-nowrap">{item.profitRate ? item.profitRate.toFixed(2) : 0}</span>%</td>
                                <td><span className="text-nowrap">¥ {showPrice(item?.totalCash)}</span></td>
                                <td><span className="text-nowrap">¥ {showPrice(item?.totalCredit)}</span></td>
                                <td><span className="text-nowrap">¥ {showPrice(item?.totalOther)}</span></td>
                                <td><span className="text-nowrap">{item?.totalCustomers}</span></td>
                                <td><span className="text-nowrap">{item?.men}</span></td>
                                <td><span className="text-nowrap">{item?.women}</span></td>
                                <td>¥ <span className="text-nowrap">{showPrice(item?.price_per_customer)}</span></td>
                              </tr>
                            );
                          })
                        : ""}
                      {monthlySaleTotalReport && (
                        <tr className="total-tr" id="totalSale">
                          <td>{t("total_label")}</td>
                          <td>{monthlySaleTotalReport?.totalTransactions}</td>
                          <td><span className="text-nowrap">¥ {showPrice(monthlySaleTotalReport?.totalSales)}</span></td>
                          <td><span className="text-nowrap">¥ {showPrice(monthlySaleTotalReport?.totalCostPrice)}</span></td>
                          <td><span className="text-nowrap">¥ {showPrice(monthlySaleTotalReport?.totalProfit)}</span></td>
                          <td><span className="text-nowrap">{monthlySaleTotalReport.profitRate ? monthlySaleTotalReport.profitRate.toFixed(2) : 0}</span>%</td>
                          <td><span className="text-nowrap">¥ {showPrice(monthlySaleTotalReport?.totalCash)}</span></td>
                          <td><span className="text-nowrap">¥ {showPrice(monthlySaleTotalReport?.totalCredit)}</span></td>
                          <td><span className="text-nowrap">¥ {showPrice(monthlySaleTotalReport?.totalOther)}</span></td>
                          <td><span className="text-nowrap">{monthlySaleTotalReport?.totalCustomers}</span></td>
                          <td><span className="text-nowrap">{monthlySaleTotalReport?.men || 0}</span></td>
                          <td><span className="text-nowrap">{monthlySaleTotalReport?.women || 0}</span></td>
                          <td><span className="text-nowrap">¥ {showPrice(monthlySaleTotalReport?.totalPricePerCustomer)}</span></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <RecordNotFound heading="lbl_record_not_found" />
              )}
            </div>
          </div>}
        </div>
      </main>
    );
}
